import axios from "axios";
import { toast } from "react-toastify";
import { getBaseUrl } from "../../Global";
import Cookies from "js-cookie";
import { type } from "@testing-library/user-event/dist/type";

export const fetchAllCategories = (setCategory , setLoader) => async (dispatch) => {
  let config = {
    method: "POST",
    url: getBaseUrl() + "/user/homedata",
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
  };

  try {
    let res = await axios(config);
    if (res.data) {
      setCategory(res.data.data);
    } else {
      toast.warning("Something went wrong");
    }
    setLoader(false)
  } catch (error) {
    console.log(error);
  }
};

export const fetchAllSubCategories = (data, setCategory , setLoader) => async (dispatch) => {
    let config = {
      method: "POST",
      url: getBaseUrl() + "/service/categoriesbyserviceid",
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      data: data,
    };

    try {
      let res = await axios(config);
      if (res.data) {
        setCategory(res.data.data);
      } else {
        toast.warning("Something went wrong");
      }
      setLoader(false)
    } catch (error) {
      console.log(error);
      setLoader(false)
    }
  };

export const fetchAllServices = (setCategory) => async (dispatch) => {
  let config = {
    method: "POST",
    url: getBaseUrl() + "/service/allserviceandcategory",
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
  };

  try {
    let res = await axios(config);
    if (res.data) {
      setCategory(res.data.data);
    } else {
      toast.warning("Something went wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

export const onSearchLocation = (data, setLocationList) => async (dispatch) => {
  let config = {
    method: "POST",
    url: getBaseUrl() + "/vendor/searchLocation",
    data: data,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
  };

  try {
    let res = await axios(config);
    console.log(res);
    if (res.data.data) {
      setLocationList(res.data.data);
    } else {
      setLocationList([]);
      toast.warning("Something went wrong");
    }
  } catch (error) {
    setLocationList([]);
    console.log(error);
  }
};

export const onSearchServices =
  (data, setServicesListCategory, setServicesListVendor, navigate) =>
  async (dispatch) => {
    let config = {
      method: "POST",
      url: getBaseUrl() + "/service/search",
      data: data,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    };

    try {
      let res = await axios(config);
      if (res?.data) {
        setServicesListCategory(res?.data?.data?.category);
        setServicesListVendor(res?.data?.data?.vendor);
        navigate("/vendor-list", {
          state: { location: data.location, services: data.search },
        });
      } else {
        toast.warning("Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

export const onGetVendorData = (id, setVendorData,setLoader) => async (dispatch) => {
  let config = {
    method: "POST",
    url: getBaseUrl() + "/vendor/findvendorbyid",
    data: id,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
  };

  try {
    let res = await axios(config);
    if (res?.data) {
      setVendorData(res?.data?.data);
    } else {
      toast.warning("Something went wrong");
    }
    setLoader(false)
  } catch (error) {
    console.log(error);
    setLoader(false)
  }
};

export const onGetCatrgoryByServicId =
  (id, setCategory , setLoader) => async (dispatch) => {
    let config = {
      method: "POST",
      url: getBaseUrl() + "/vendor/findvendorbycategoryid",
      data: id,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    };

    try {
      let res = await axios(config);
      if (res?.data) {
        setCategory(res?.data?.data);
      } else {
        toast.warning("Something went wrong");
      }
      setLoader(false)
    } catch (error) {
      console.log(error);
      setLoader(false)
    }
  };

export const onSendQuery = (data, handleFalse) => async (dispatch) => {
  let config = {
    method: "POST",
    url: getBaseUrl() + "/user/bestdeal",
    data: data,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
  };

  try {
    let res = await axios(config);
    if (res?.data) {
      toast.success("Successfully send");
      handleFalse();
    } else {
      toast.warning("Something went wrong");
    }
  } catch (error) {
    console.log(error);
  }
};

export const onSendBusinessDetails =  (data, setBusiness, business) => async (dispatch) => {
    let config = {
      method: "POST",
      url: getBaseUrl() + "/user/applyforvendor",
      data: data,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    };

    try {
      let res = await axios(config);
      if (res?.data) {
        toast.success("Successfully send");
        setBusiness({
          ...business,
          name: "",
          businessName: "",
          pinCode: "",
          number: "",
          city: "",
        });
      } else {
        toast.warning("Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  export const fetchAllPopularVendor = (setVendor , setLoader) => async (dispatch) => {
    let config = {
      method: "get",
      url: getBaseUrl() + "/vendor/topVendorListing",
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    };
  
    try {
      let res = await axios(config);
      if (res.data) {
        setVendor(res.data.data);
      } else {
        toast.warning("Something went wrong");
      }
      setLoader(false)
    } catch (error) {
      console.log(error);
    }
  };

  export const onFetchNotification =(setNotification) => async (dispatch) => {
    let config = {
      method: "POST",
      url: getBaseUrl() + "/user/mynotification",
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    };

    try {
      let res = await axios(config);
      if (res?.data) {
        setNotification(res?.data?.data);
      } else {
        toast.warning("Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  export const onSendAdvertisingDetails =  (data, setBusiness, business) => async (dispatch) => {
    let config = {
      method: "POST",
      url: getBaseUrl() + "/user/applyforadvertising",
      data: data,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    };

    try {
      let res = await axios(config);
      if (res?.data) {
        toast.success("Successfully send");
        setBusiness({
          ...business,
          name: "",
          businessName: "",
          pinCode: "",
          number: "",
          city: "",
        });
      } else {
        toast.warning("Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

export const onSendLocationService = (location, services) => (dispatch) => {
  let data = {
    location: location,
    services: services,
  };
  dispatch({ type: "USER_LOCATION_SERVICES", payload: data });
};
