import React from "react";
import Header from "./LandingPageComponent/Header/Header";
import Header1 from "./LandingPageComponent/Header/Header1";
import Footer from "./LandingPageComponent/Footer";

const PrivacyAndPolicy = () => {
  return (
    <div>
      <Header />
      <Header1 />
      <div className="row justify-content-center py-5">
        <div className="col-md-10 ">
        
          <div class="term-body mt-5">
          <h1>
                <>Privacy and Policy</>
              </h1>
            <ol>
              <p>
                By using this site, you agree to abide by the terms and
                conditions set forth in this notice. Every Material of this site
                are protected under copyright and other intellectual property
                laws and may not be republished by you or provided by you to
                third parties without the express written consent of Home
                Shifting Mart. Also, downloading and copying the Materials is
                subject to the following restrictions: • You must retain all
                copyright and other proprietary notices contained in the
                Materials on all copies of the Materials that you make • You may
                not modify the Materials in any way or reproduce or publicly
                display, perform or distribute or otherwise use them for any
                public purpose • You must give notice of these restrictions on
                use of the Materials to any person to whom you provide the
                Materials
              </p>

              <p>
                Every software subjected to this site is and are available to
                operate is the sole property of home shifting mart, no one can
                copy the content and software, appearance of this site. You are
                bounded to use this software for the purpose of visiting the
                site or downloading the material from it. You are not allowed to
                redistribute, decompile, reverse engineer, disassemble or
                otherwise deal with the software.
              </p>

              <p>
                This Web Site and the information, names, images, pictures,
                logos and icons concerning, regarding or relating to Home
                Shifting Mart. and its affiliates and services are provided "as
                is" and on an "as available" basis, without any representation
                or endorsement made, and without warranty of any kind, whether
                expressed or implied, including but not limited to an implied
                warranty of merchantability, fitness for a particular purpose,
                or non-infringement of others' intellectual property rights.
                Under no circumstances, Home Shifting Mart. or any of its
                affiliates, contractors, employees, directors or officers be
                liable for any damages, including without limitation special,
                indirect, or consequential damages (INCLUDING, WITHOUT
                LIMITATION, DAMAGES FOR LOSS OF PROFITS, BUSINESS INTERRUPTION,
                OR LOSS OF INFORMATION) resulting from access or use, or
                inability to access or use, this Web Site or arising out of any
                materials, information, qualifications, opinions or
                recommendations on this Web Site. We may change the contents of
                the website at any point of time without any pre-intimation or
                permission.
              </p>

              <p>
                The protection of Personal Data and the privacy of individuals
                is one of Home Shifting Mart' priorities. As a Data Controller,
                we are committed to comply with Indian and international
                regulations regarding the protection of personal data, and to
                implement all necessary measures to respect the confidentiality
                of Your Personal Data and to ensure their protection.
              </p>

              <p>
                Why does Home Shifting Mart process your data? Home Shifting
                Mart processes the Personal Data for the following purposes.
                These purposes each relate to a lawful basis for processing, as
                required by applicable law.
              </p>

              <table>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Purposes</th>
                    <th>Legal basis</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Delivering our Services</td>
                    <td>
                      Allow You to access some of Our Services and describe our
                      Services to current and prospective business partners and
                      to other third parties
                    </td>
                    <td>
                      These processing are necessary for the performance of the
                      contract between You and Home Shifting Martor a contract
                      between You and third party that has a contract with Home
                      Shifting Mart.
                    </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>Establishing accounts to use the Services</td>
                    <td>
                      These processing may also be necessary for the purpose of
                      the legitimate interests pursued by Home Shifting Mart to
                      deliver the Service.
                    </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>Allow You to access our facilities.</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>Deliver our Services</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>
                      Communicating with you in connection with the Service or
                      as a result of a request
                    </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>
                      Sharing with our service providers that perform certain
                      business functions or services on behalf and with whom are
                      bound by contractual obligations consistent with this
                      Privacy Policy
                    </td>
                  </tr>
                </tbody>
              </table>

              <p>
                Cancellation and refund policy: Cancellation and refund policy
                is of vendors only we do not provide any specific policy for
                cancellation of logistics services
              </p>

              <p>
                Damage to the consignment is the responsibility of third party
                only Home Shifting Mart is not responsible for any damage during
                transit. And, We do not provide any insurance coverage as per
                sec 11 of carriage by road act, 2007.
              </p>

              <ol>
                <p>Privacy policy</p>
                <p>Software</p>
                <p>General</p>
                <p>Protection of personal data</p>
                <p>Our policies for cancellation and refund are as follows</p>
                <p>Damage coverage popcy</p>
              </ol>
            </ol>

            <div></div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyAndPolicy;
