import React from "react";
import { Container } from "react-bootstrap";

const TextPage = () => {
  return (
    <Container fluid style={{ backgroundColor: "#f5f5f5" }}>
      <div className="row justify-content-center py-5">
        <div className="col-md-10 mt-4">
          <p className="text-start">
            Welcome to Home Shifting Mart, your ultimate destination for
            comprehensive assistance in daily and special planning and
            purchasing endeavors. We're delighted to introduce our renowned
            customer support hotline, 7840044700, and highlight our firm grip on
            local business information across India.
          </p>
          <p className="text-start">
            Our offerings encompass more than just furnishing addresses and
            contact information for businesses nationwide. We go beyond,
            facilitating orders and reservations for a wide array of needs such
            as leisure, medical, financial, travel, and domestic purposes. Our
            comprehensive database includes business details across diverse
            sectors like Packers and Movers, Repairing, Pest Control, Home
            Decor, Personal and Transporter, Insurance, and more from every
            corner of the country. From major metropolises like Mumbai, Delhi,
            Bangalore, Hyderabad, Chennai, Ahmedabad, and Pune to smaller cities
            nationwide, we provide a vast and inclusive network of information.
          </p>
          <p className="text-start">
            With our 'Free Listing' feature, we offer a platform to highlight
            diverse specialties. We deliver information through multiple
            channels such as phone, SMS, web, App, and WAP. Additionally, we
            provide a space for you to share your experiences using our 'Rate &
            Review' feature. Our commitment to value extends to 'Best Deals,'
            'Last Minute Deals,' and 'Live Quotes,' ensuring that you have
            access to the finest bargains available in the market
          </p>
        </div>
      </div>
    </Container>
  );
};

export default TextPage;
