import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";

const MyDetails = () => {

  const userData = useSelector((state) => state.auth);

  const [details, setDetails] = useState({
    name: "",
    email: "",
    mobileNo: "",
    gender: "",
    img: "",
  });

  useEffect(()=>{
    setDetails({
      ...details,
      name:userData?.user?.name,
      email:userData?.user?.email,
      mobileNo:userData?.user?.mobile_number,
      gender:userData?.user?.gender==="male"?"1":"2",
      img:userData?.user?.profile_image
    }) 
  },[])

  return (
    <><div className="bg-box p-4">
    <h3 className="py-4">My Details</h3>
    <h6 className="py-4">Personal Information</h6>
    <hr />
    <Form className="p-4 ">
      <div className="row ">
      <div className="col-md-12 d-flex justify-content-center ">
               <img src={details.img} width={"150px"}/>
              </div>
        <div className="col-md-6 ">
        <Form.Label><p className="m-0">First Name</p></Form.Label>
          <Form.Group className="input-box" controlId="">
            <Form.Control type="text" placeholder="Enter Your first name"  value={details.name}/>
          </Form.Group>
        </div>

        <div className="col-md-6 ">
            <Form.Label><p className="m-0">Gender</p></Form.Label>
          <Form.Select
            aria-label="Default select example"
            className="mt-4 p-3"
          >
            <option>Choose your Gennder</option>
            <option value="1">Male</option>
            <option value="2">Female</option>
          </Form.Select>
        </div>

     
      </div>
      <div className="row ">
        <div className="col-md-6 ">
            <Form.Label><p className="m-0">Mobile No</p></Form.Label>
          <Form.Group className="input-box" controlId="">
            <Form.Control type="text" placeholder="Enter Your mobile no" value={details.mobileNo} />
          </Form.Group>
        </div>
        <div className="col-md-6">
            <Form.Label><p className="m-0">Email Id</p></Form.Label>
          <Form.Group className="input-box" controlId="">
            <Form.Control type="text" placeholder="Enter your email Id" value={details.email}/>
          </Form.Group>
        </div>
      </div>

      <div className="row ">
        {/* <div className="col-md-6 ">
            <Form.Label><p className="m-0">DOB</p></Form.Label>
          <Form.Group className="input-box" controlId="">
            <Form.Control type="text" placeholder="DOB" />
          </Form.Group>
        </div> */}
       
      </div>
      <div className="text-center mb-5">
        <button className="category-btn">Save</button>
      </div>
    </Form>
  </div>

  </>
  );
};

export default MyDetails;
