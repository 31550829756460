import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import img from "../../Assets/popularvendors.png";
import { fetchAllPopularVendor } from "../Action/FetchAction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const TopVendors = () => {
  const dispatch = useDispatch();
 
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      partialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1.5,
      partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 30,
    },
  };

  const [vendor , setVendor] = useState([])

  useEffect(()=>{
    dispatch(fetchAllPopularVendor(setVendor));
  },[])

  console.log(vendor,"vendor")

  const items = [
    {
      image: img,
    },
    {
      image: img,
    },
    {
      image: img,
    },
    {
      image: img,
    },
    {
      image: img,
    },
    // Add other items here...
  ];

  return (
    <div style={{ backgroundColor: "#f5f5f5" }} className="py-5">
      <Container className="carousel-container  ">
        <h1 className="mb-4">Top Popular Vendors</h1>
        <Carousel
          additionalTransfrom={0}
          arrows
          autoPlay
          autoPlaySpeed={3000}
          centerMode={false}
          className=""
          containerClass="container-with-dots"
          dotListClass=""
          draggable={true}
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={responsive}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {vendor.map((item, index) => (
            <WithStyles
              key={index}
              description={item.description}
              headline={item.headline}
              image={item.bannerImage}
              id={item._id}
            />
          ))}
        </Carousel>
      </Container>
    </div>
  );
};



// Dummy component to represent the WithStyles component.
const WithStyles = ({ description, headline, image, id }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/vendor-profile", {
      state: {
        vendorId: id,
      },
    });
  };

  return (
    <div>
      <img
        width="100%"
        style={{ borderRadius: "5px", height: "22rem" }}
        src={image}
        alt={headline}
        onClick={handleClick} 
      />
      {/* Uncomment and modify the following lines if needed */}
      {/* <div className='carousel-text'>
        <h4>{headline}</h4>
      </div> */}
      {/* <p>{description}</p> */}
    </div>
  );
};

export default TopVendors;
