import React from "react";
import { Form } from "react-bootstrap";

const TermCondition = () => {
  return (
    <>
      <div className="bg-box p-5">
      <div class="term-body">
              <h3>
                <>Terms and conditions</>
              </h3>
            <div>

              <p>
                <br />
                <h6>
                  <>1. Areas of application</>
                </h6>
                <br />1 -These logistics terms and conditions apply to all
                (supplementary) logistics services not covered by a
                transportation contract in accordance with section
              </p>

              <p>
                2 - of the Home shifting mart Terms and Conditions– if
                applicable – or a freight, a forwarding or a warehousing
                contract but that are provided by a service provider in
                connection with such a transportation contract. Such logistics
                services may be activities for the principal or third parties
                named by the principal, like, for example, order acceptance
                (Call Center), treatment of goods, country or customer-specific
                adaptation of goods, assembly, repair, quality control, price
                labelling, shelf service, installation or implementation of
                goods and commodities or activities in
                <br />
                connection with the planning, implementation, control or
                monitoring of the management of orders, processes, sales,
                returns, disposals, recycling and information technology.
                <br />
                3 - Principal is that party that instructs its contracting
                partner with the provision of logistics services for itself or
                third parties.
                <br />
                4 - Service provider is that party that has been instructed to
                provide logistics services.
                <br />
                5 -&nbsp; If the Home shifting mart have been agreed, the
                logistics terms shall have precedence if individual clauses
                contradict each other or if there is a doubt about which terms
                and conditions a situation is subject to.
                <br />6 - The logistics terms and conditions do not apply to
                contracts with end-users.
              </p>

              <p>
                <br />
                <h6>2. Electronic data exchange</h6>
              </p>

              <p>
                <br />
                1- Each party has the right to create, transmit and exchange
                statements and notices electronically (electronic data ex-
                change), for as long as the transmitting party can be
                identified. The transmitting party is responsible for loss and
                correctness of the transmitted data.
                <br />
                2- If the communication between two data processing systems
                requires the provision of a common IT-interface by the logistics
                provider the costs incurred for the necessary work shall be
                borne by the principal. Furthermore, each party is obliged to
                take the usual security and monitoring measures and to protect
                the electronic data exchange against unauthorized access by
                third parties and to prevent the manipulation, loss or
                destruction of electronically transmitted data.
                <br />
                3- Each party shall appoint one or more contact persons for the
                receipt of information, statements and questions regarding the
                contract and communicate their names and contact addresses to
                the other party. If a party should not appoint a contact person,
                the person who signed the contract for that party shall be
                considered the contact person.
                <br />
                4- Electronically or digitally created documents shall be
                considered equal to written documents.
              </p>

              <p>
                <br />
                <h6>3. Confidentiality</h6>
                <br />
                1- Each party is obliged to treat any data and information not
                publicly accessible as confidential and to use these exclusively
                for the purpose intended. Only data and information needed by
                third parties (insurers, sub-contractors) for the execution of
                their duties may be passed on to them. The same rules of the
                confidentiality of electronic data and information shall apply.
                <br />
                2- The confidentiality rule does not apply to data and
                information that must be passed on to third parties, especially
                public authorities, due to legal obligations. The other party is
                to be informed about such obligation without delay.
              </p>

              <p>
                <br />
                <h6>
                  4. Duties of the principal, protection of intellectual
                  property
                </h6>
                <br />
                1- The principal is obliged, especially if as “system leader“,he
                determines the procedure for which the service provider has been
                engaged, to provide the necessary items, information
                <br />
                and authorisations required for the performance of the logistics
                services and give appropriate assistance, especially
                <br />
                2- to provide products and materials,
                <br />
                3-&nbsp; to inform the service provider about specific
                characteristics of goods and procedures and any legal or other
                duties connected herewith and, as far as necessary, to provide
                training courses for his staff and
                <br />
                4- to develop and update descriptions of procedures and
                materials (assembly instructions, construction plans and other
                plans) and to monitor their observation by the service provider.
                These provisions and the participation must be carried out
                completely and on time. This includes also all information
                required for an optimal planning of capacities.
              </p>

              <p>
                <br />
                5-&nbsp; The documents provided under section 4.1 remain the
                intellectual property of the principal. A right to seizure or
                retention may not be exercised by the service provider.
              </p>

              <p>
                <br />
                <h6>5. Duties of the service provider</h6>
              </p>

              <p>
                <br />
                1-&nbsp; The service provider is obliged to provide his services
                in accordance with the instructions of the principal of section
                4. He is entitled, but not obliged, to monitor these.
                <br />
                2-&nbsp; The service provider who provides logistics services
                within the operations of the principal or on his instructions
                within the operations of a third party (e.g., shelf servicing)
                provides such services in accordance with the instructions of
                and at the risk of the principal.
                <br />
                3-&nbsp; The service provider is obliged to inform the principal
                without delay about any objections or irregularities occurring
                in the course of carrying out his contractual duties and to
                document these.
              </p>

              <p>
                <br />
                <h6>6. Hindrances, force majeure</h6>
              </p>

              <p>
                <br />
                1-&nbsp; Hindrances beyond the control of a contracting party
                release the parties from their duty to perform for the duration
                and extent of that hindrance. Hindrances are: strikes,
                lock-outs, force majeure, civil commotion, war or terrorist
                acts, measures taken by public
                <br />
                authorities or any other unforeseeable, unavoidable and grave
                events.
                <br />
                2-&nbsp; In the case of exemption according to 6.1 each
                contracting party is obliged to,
                <br />
                A- immediately inform the other party and
                <br />
                B- minimise, within reason, the effects on the other party as
                much as possible.
              </p>

              <p>
                <h6>7. Modifications of the contract</h6>
              </p>

              <p>
                <br />
                1-&nbsp; Agreements on prices and services always refer
                exclusively to the specifically named services and to a
                generally steady volume of goods and activities. They assume
                unchanged
                <br />
                requirements for data processing, quality agreements and
                procedural instructions and unchanged energy- and personnel
                costs as well as public levies.
                <br />
                2-&nbsp; If the conditions described under 7-1 change, either
                party may request new negotiations to modify the contract with
                effect from the first day of that month which follows the month
                in
                <br />
                such request was made, unless the changes were already known to
                the requesting party at the time of reaching the agreement. The
                modifications of the contract must be based
                <br />
                on the identifiable changes including the rationalization
                effects.
                <br />
                3-&nbsp; Should the contracting parties fail within one month
                after the request for changes was made to agree on such changes,
                can either party terminate the agreement by giving notice of one
                month in the case of the contract being valid for one year, or
                three months if the contract has a longer duration. Such a
                termination may be declared only within one month
                <br />
                after the failure to change the contract.
              </p>

              <p>
                <br />
                <h6>8. Settlement, retention</h6>
              </p>

              <p>
                <br />
                The right of settlement against existing payments due or
                retention arising from a contract for logistics services
                according to 1.1 and other demands may only be exercised if no
                reasoned objection has been made.
              </p>

              <p>
                <br />
                <h6>9. Right of seizure and retention, ownership</h6>
              </p>

              <p>
                1-&nbsp; The service provider has a right of seizure and
                retention for all payments due to him in connection with his
                activities for the principal in accordance with section 1.1 for
                any goods
                <br />
                and other values in his possession. This right of seizure and
                retention does not go beyond the legislation concerning seizure
                and retention.
                <br />
                2- The service provider may exercise his right of seizure and
                retention in connection with other contracts concluded with the
                principal for logistics services in accordance with section
                <br />
                1.1 only if this is undisputed or if the economic situation of
                the principal constitutes a threat to the payments to be made to
                the service provider.
                <br />
                3-&nbsp; The principal is entitled to prevent the exercise of
                the right of seizure if he offers the service provider suitable
                security (e.g., bank guarantee).
                <br />
                4-&nbsp; Section 4.2 remains unaffected.
                <br />
                5-&nbsp; In the case of the service provider in the course of
                his duties according to section 1.1 also transferring ownership
                to the principal, such ownership remains with the service
                provider until full payment has been made.
              </p>

              <p>&nbsp;</p>

              <p>
                <h6>10. Acceptance, deficiencies, delays</h6>
              </p>

              <p>
                <br />
                1-&nbsp; If a formal acceptance of a logistics service is
                required from the principal, it may, due to the co-operative
                nature of logistics services, take place through use, re-sale or
                further
                <br />
                development, delivery to the principal or third parties
                specified by him. If a formal acceptance of a logistics service
                is not possible, completion shall be deemed as acceptance.
                <br />
                2-&nbsp; The principal is obliged to notify the service provider
                about apparent deficiencies at the time of acceptance. The
                notification must be made in writing or electronically (see 2).
                Noti-
                <br />
                fication is considered to have been made if it was sent in time
                and provided it reached the service provider.
                <br />
                3-&nbsp; If the principal fails to notify the service provider
                the logistics service is deemed to have been performed in
                accordance with the contract, unless the service provider
                maliciously
                <br />
                withheld information about the deficiency.
                <br />
                4-&nbsp; Claims because of delays become void if they are not
                made by the principal to the service provider within twenty-one
                days after the service was provided.
              </p>

              <p>&nbsp;</p>

              <p>
                <h6>11. Rules for vendors</h6>
              </p>

              <p>&nbsp;</p>

              <p>Eligibility</p>

              <p>&nbsp;</p>

              <p>
                1-&nbsp; Vendor is engaged in the business of providing
                Logistics Services and has agreed to provide service for the
                Leads from Home Shifting Mart.
              </p>

              <p>
                2-&nbsp; Customer information shall be provided to the Vendor(s)
                by way of shared Lead(s). There is no exclusivity in providing
                Lead(s).
              </p>

              <p>
                3-&nbsp; Lead feedback from the Vendor is to be submitted to
                Home Shfting Mart within 24 hours.
              </p>

              <p>&nbsp;</p>

              <p>4-&nbsp; Responsibility</p>

              <p>&nbsp;</p>

              <p>
                i) Home Shifting Mart is a service provider and is not
                responsible for any loss after dealing of customer and vendor.
                Vendor is solely responsible.
              </p>

              <p>ii) Registration charges are not refundable</p>

              <p>
                iii) We do not ask any other fees, other than registration so be
                aware of frauds. For any inconvenience we are not responsible.
              </p>

              <p>
                iv) Vendors can get only list of customers from Home Shiting
                Mart. Customer reliability is vendors problem.
              </p>

              <p>&nbsp;</p>

              <ul>
                <li>
                  <ul>
                    <h6>Termination&nbsp; &nbsp;</h6>
                  </ul>
                </li>
              </ul>

              <p>&nbsp;</p>

              <p>
                i) Agreement shall be terminated if customer reviews bad about
                vendor more than once.
              </p>

              <p>ii) Termination from either side can not be rebound</p>

              <p>&nbsp;</p>

              <p>&nbsp;</p>

              <p>
                <h6>11 Limitation</h6>
              </p>

              <p>
                <br />
                1-&nbsp; Any claims from a contract according to 1.1 expire
                after one year.
                <br />
                2 - The limitation period starts for all claims with the day of
                delivery with the day of acceptance in accordance with section
                11.1.
                <br />
                3-&nbsp; The above limitation does not apply
                <br />
                A-&nbsp; in the case of fatal injury, personal injury or damage
                to
                <br />
                health or
                <br />
                B-&nbsp; if legislation regarding limitation has overriding
                effect
              </p>

              <p>
                Note: All the claims available at our website are subjected to
                terms and conditions.
              </p>

              <p>&nbsp;</p>
              <p></p>
            </div>
          </div>
      </div>
    </>
  );
};

export default TermCondition;
