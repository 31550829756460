import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import pan from "./../../Assets/dummy_pan.png"

import { useDispatch } from "react-redux";
import downloadapp from "../../Assets/download_app.png";
import { fetchAllCategories, fetchAllServices } from "../Action/FetchAction";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const TopServices = () => {
  const dispatch = useDispatch();
  const userArea = Cookies.get("userArea");
  const navigate = useNavigate();

  const [services, setServices] = useState([]);
  

  useEffect(() => {
    dispatch(fetchAllCategories(setServices));
  }, []);

  const handleCategory = (elem) => {
    navigate("/vendor-list", {
      state: { categoryId: elem?._id, location: userArea, services: elem.name },
    });
  };

  return (
    <div className="mb-4">
      <Container className="carousel-container my-5">
        <h1 className="py-4 ">Top Popular Services</h1>
        

        <div className="row justify-content-between">
          <div className=" services-box">
            <h4 className="px-4 mt-2">Cleaning Services</h4>

            <div className="services-card ">
              {services?.cleaningService?.slice(0, 3)?.map((elem) => {
                return (
                  <div
                    onClick={() => handleCategory(elem)}
                    className="cursor-pointer popular-service"
                  >
                    <img src={elem.image}  />
                    <p className="text-center">{elem.name}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className=" services-box">
            <h4 className="px-4 mt-2">Courier Services</h4>

            <div className="services-card ">
              {services?.courierService?.slice(0, 3)?.map((elem) => {
                return (
                  <div
                    onClick={() => handleCategory(elem)}
                    className="cursor-pointer popular-service"
                  >
                    <img src={elem.image}  />
                    <p className="text-center">{elem.name}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="row justify-content-between">
          <div className=" services-box">
            <h4 className="px-4 mt-2">Pest Control Services</h4>

            <div className="services-card ">
              {services?.pestCantrolService?.slice(0, 3)?.map((elem) => {
                return (
                  <div
                    onClick={() => handleCategory(elem)}
                    className="cursor-pointer popular-service"
                  >
                    <img src={elem.image}  />
                    <p className="text-center">{elem.name}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className=" services-box">
            <h4 className="px-4 mt-2">Repair Services</h4>

            <div className="services-card ">
              {services?.repairService?.slice(0, 3)?.map((elem) => {
                return (
                  <div
                    onClick={() => handleCategory(elem)}
                    className="cursor-pointer popular-service"
                  >
                    <img src={elem.image}  />
                    <p className="text-center">{elem.name}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="row verified_box mt-5">
          <div className="col-md-3 d-flex align-items-center">
            <h6>All Vendors Verified By</h6>
          </div>
          <div className="col-md-9">
            <div className="row">
            <div className="col-md-3 col-3 services-card ">
              <div className="cursor-pointer popular-service">
                <img
                  src="https://etvbharatimages.akamaized.net/etvbharat/prod-images/22-07-2023/1200-900-19065540-thumbnail-16x9-aadhar-aspera.jpg"
                style={{height:"10rem"}}
                />
                <p className="text-center">Aadhar Card</p>
              </div>
            </div>
            <div className="col-md-3 col-3 services-card ">
              <div className="cursor-pointer popular-service">
                <img
                  src={pan}
                style={{height:"10rem"}}
                />
                <p className="text-center">Pan Card</p>
              </div>
            </div>
            <div className="col-md-3 col-3 services-card ">
              <div className="cursor-pointer popular-service">
                <img
                  src="https://img.freepik.com/free-vector/gradient-golden-luxury-certificate_52683-70557.jpg?w=900&t=st=1702897923~exp=1702898523~hmac=3e78d631b2a610703e35d2e6cdc2f7d281f4742610b7171238206cab49d70019"
                style={{height:"10rem"}}
                />
                <p className="text-center">Company Certificate</p>
              </div>
            </div>
            <div className="col-md-3 col-3 services-card ">
              <div className="cursor-pointer popular-service">
                <img
                  src="https://img.freepik.com/free-vector/open-folder-with-documents_1262-4574.jpg?w=740&t=st=1702898015~exp=1702898615~hmac=221486c0e7b8ab233bd24bcd7fdd1b92fde9799b0f89cccce6d2a02ba90f0962"
                style={{height:"10rem"}}
                />
                <p className="text-center">Others</p>
              </div>
            </div>
            </div>
          </div>
        </div>
      </Container>
      <img src={downloadapp} style={{ width: "100%" }} />
    </div>
  );
};

// Dummy component to represent the WithStyles component.
// const WithStyles = ({ headline, category }) => (
//   <div className="services-box">
//     <h4 className="px-4 mt-2">{headline}</h4>

//     <div className="services-card ">
//       {category?.map((elem) => {
//         return (
//           <div>
//             <img src={elem.image} width={"100px"} height={"100px"} />
//             <p className="">{elem.categoryName}</p>
//           </div>
//         );
//       })}
//     </div>
//     <div className="carousel-text"></div>
//   </div>
// );

export default TopServices;
