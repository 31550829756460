import React from "react";
import img1 from "../../Assets/blog-img1.png";
import img2 from "../../Assets/blog-img2.png";
import Card from "react-bootstrap/Card";
import { Container, Button } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const BlogPage = () => {
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4,
          partialVisibilityGutter: 40,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1.5,
          partialVisibilityGutter: 30,
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          partialVisibilityGutter: 30,
        },
      };
  const items = [
    {
      description:
        "Appending currency sign to a purchase form in your e-commerce site using plain JavaScript.",
      headline: "One morning when Jack wake up and see",
      image: img1,
    },
    {
      description:
        "Appending currency sign to a purchase form in your e-commerce site using plain JavaScript.",
      headline: "One morning when Jack wake up and see",
      image: img2,
    },
    {
      description:
        "Appending currency sign to a purchase form in your e-commerce site using plain JavaScript.",
      headline: "One morning when Jack wake up and see",
      image: img1,
    },
    {
      description:
        "Appending currency sign to a purchase form in your e-commerce site using plain JavaScript.",
      headline: "One morning when Jack wake up and see",
      image: img2,
    },
    {
      description:
        "Appending currency sign to a purchase form in your e-commerce site using plain JavaScript.",
      headline: "One morning when Jack wake up and see",
      image: img1,
    },
    // Add other items here...
  ];
  return (
    <div >
      <Container className="carousel-container mt-5 ">
        <h1 className="py-5 text-center">From Our Blog</h1>
        <Carousel
          additionalTransfrom={0}
          arrows
          autoPlay
          autoPlaySpeed={300111110}
          centerMode={false}
          className=""
          containerClass="container-with-dots"
          dotListClass=""
          draggable={true}
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={responsive}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {items.map((item, index) => (
            <WithStyles
              key={index}
              description={item.description}
              headline={item.headline}
              image={item.image}
            />
          ))}
        </Carousel>
      </Container>
    </div>
  );
};

// Dummy component to represent the WithStyles component.
const WithStyles = ({ description, headline, image }) => (
    <Card style={{width:"80%"}} >
    <Card.Img variant="top" src={image} />
    <Card.Body>
      <Card.Title><h5 style={{fontWeight:"600"}}>{headline}</h5></Card.Title>
      <Card.Text className="m-0"><p className="m-0">{description}</p></Card.Text>
      <button className="blog-btn">Read more</button>
    </Card.Body>
  </Card>
);


export default BlogPage;
