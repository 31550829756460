import React, { useEffect, useState } from "react";
import Header from "../LandingPageComponent/Header/Header";
import Header1 from "../LandingPageComponent/Header/Header1";
import img from "../../Assets/cleaning-services-3.png";
import TextPage from "../LandingPageComponent/TextPage";
import ReachEmployers from "../LandingPageComponent/ReachEmployers";
import Footer from "../LandingPageComponent/Footer";
import TopVendors from "../LandingPageComponent/TopVendors";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import { Link, useLocation } from "react-router-dom";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { onGetVendorData, onSendQuery } from "../Action/FetchAction";
import { MdVerified } from "react-icons/md";
import { RiMapPinFill } from "react-icons/ri";
import { FaRegClock } from "react-icons/fa";
import { MdPayment } from "react-icons/md";
import { FaCalendarDay } from "react-icons/fa";
import { FaGlobe } from "react-icons/fa";
import { RiWhatsappFill } from "react-icons/ri";
import { MdDirections } from "react-icons/md";
import VendorNavbar from "../VendorList/VendorNavbar";
import Loader from "../Loader/Loader";
import { FaPhoneAlt } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import twitter from "../../Assets/Icons/twitter.png";
import linkedin from "../../Assets/Icons/linkedin.png";
import facebook from "../../Assets/Icons/facebook.png";
import instagram from "../../Assets/Icons/instagram.png";
import { FaShareAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import Login from "../Dialogbox/Login";


const VendorProfile = () => {
  const location = useLocation();
  const vendorId = location.state.vendorId;
  const dispatch = useDispatch();
  const userAuth = useSelector(state=>state.auth)

  const [modalShow, setModalShow] = useState(false);
  const [vendorData, setVendorData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [LoginModal, setLoginModal] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [deal, setDeal] = useState({
    name: "",
    email: "",
    mobile: "",
    query: "",
  });
  const [reviewLength, setReviewLength] = useState(2);
  const [imgLength, setImgLength] = useState(9);

  const handleFalse = () => {
    setModalShow(false);
  };
  const handleTrue = () => {
    setModalShow(true);
  };

  useEffect(() => {
    setLoader(true);
    let data = {
      id: vendorId,
    };
    dispatch(onGetVendorData(data, setVendorData, setLoader));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDeal({
      ...deal,
      [name]: value,
    });
  };

  const handleMobile = (e) => {
    e.stopPropagation();
    if(userAuth?.user?.name){
      setMobile(!mobile);
    }else{
      toast.warning("Please login first")
      // navigate("/")
      setModalShow1(true)
    }
  };

  const handleQuery = () => {
    let data = {
      vendorId: vendorId,
      name: deal.name,
      email: deal.email,
      number: deal.mobile,
      query: deal.query,
    };
    dispatch(onSendQuery(data, handleFalse));
  };

  const handleReviews = () => {
    setReviewLength(vendorData?.reviews?.reviewsData?.length);
  };
  const handleImgLength = () => {
    setImgLength(vendorData?.gallary?.length);
  };

  const openWhatsAppChat = (number) => {
    const whatsappUrl = `https://wa.me/${number}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <>
      <VendorNavbar location={location} />
      <div className="bg-profile-div">
        <img className="bg-profile" src={vendorData?.vendor?.bgImage} />
      </div>
      <div className="row justify-content-center">
        <div className="col-md-10">
          <div className="row">
            <div className="col-md-3 position-relative">
              <div className="profile-img-div">
                <img
                  className="profile-img"
                  src={vendorData?.vendor?.profileImage}
                />
              </div>
            </div>
            <div className="col-md-9 py-4 px-4">
              <div className="row">
                <div className="col-md-4">
                  <div className="mt-4">
                    <h3>{vendorData?.vendor?.companyName}</h3>
                    <div className="">
                      <p className="m-0">{vendorData?.vendor?.area}</p>
                      <p className="m-0 ">
                        <span className="">{vendorData?.vendor?.rating}</span>
                        <span className="px-4">
                          {Array.from({
                            length: vendorData?.vendor?.rating,
                          }).map((elem, id) => {
                            return (
                              <FaStar
                                style={{ fontSize: "2.0rem", color: "#de3c3c" }}
                              />
                            );
                          })}
                        </span>
                        <span className="px-4">
                          {vendorData?.vendor?.ratingCount} rating
                        </span>
                      </p>
                    </div>
                    
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="">
                  <div className="d-flex  mx-4">
                     {vendorData?.vendor?.isVefified? <div>
                        <p className="worker-review">
                          {" "}
                          <RiVerifiedBadgeFill
                            fontSize={"2.6rem"}
                            style={{ marginRight: "1.0rem" }}
                          />{" "}
                          Verified
                        </p>
                      </div>:""}
                      {vendorData?.vendor?.isExpert?<div>
                        <p className=" worker-expert">Expert</p>
                      </div>:""}
                    </div>
                    <div className="d-flex justify-content-between mx-4 position-relative">
                      <button className="worker-list-call"
                      onClick={handleMobile}
                      >
                        <FaPhoneAlt
                          fontSize={"2.6rem"}
                          style={{ marginRight: "1.0rem" }}
                        />
                        Call Now
                      </button>
                      {mobile ? (
                <div className="mobile-modal bg-box">
                  <div className="d-flex justify-content-between">
                    <p className="mobile-modal-text">Contact information</p>
                  <div    style={{ position: "absolute", right: "3%", fontSize: "2.2rem" , top:'10%' }} onClick={()=>setMobile(false)}>
                    <RxCross2 />
                  </div>
                  </div>
                  <div className="text-start"><FaPhoneAlt
                    fontSize={"2.0rem"}
                    style={{ marginRight: "1.0rem" }}
                  />
                  {vendorData?.vendor?.mobileNumber}</div>
                </div>
              ) : (
                ""
              )}
                      <button className="worker-list-deal" onClick={handleTrue}>
                        Best Deal
                      </button>
                      <button className="worker-list-chat" onClick={()=>openWhatsAppChat(vendorData?.vendor?.whatsappNumber)}>
                        <RiWhatsappFill
                          fontSize={"2.6rem"}
                          style={{ marginRight: "1.0rem", color: "green" }}
                        />
                        Chat Now
                      </button>
                      <button className="worker-list-chat">
                        <MdDirections
                          fontSize={"2.6rem"}
                          style={{ marginRight: "1.0rem", color: "#5271ff" }}
                        />{" "}
                        Direction
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div>
          <div className="d-flex justify-content-between">
            <div className="d-flex vendorprofile-navtab">
              <p className="px-4 text-dark"onClick={()=>window.scrollTo(0, 500)}>Details</p>
              <p className="px-4 text-dark"onClick={()=>window.scrollTo(0, 500)}>Documents</p>
              <p className="px-4 text-dark" onClick={()=>window.scrollTo(0, 1000)}>Photos</p>
              <p className="px-4 text-dark" onClick={()=>window.scrollTo(0, 1000)}>Reviews</p>
              <p className="px-4 text-dark">Share</p>
            </div>
            <div className="profile-share-icon"><FaShareAlt fontSize={"30px"}/></div>
          </div>
          </div>
          <hr/>
          <div className="row justify-content-around">
            <div className="col-md-5">
              <div className="worker-details">
                <h6>Details</h6>
                <hr />
                <p className="py-4">
                  <RiMapPinFill fontSize={"28px"} />{" "}
                  {vendorData?.vendor?.address?.map((addressItem, index) => (
                    <React.Fragment key={index}>
                      {addressItem.area} ,{addressItem.buldingName} ,
                      {addressItem.city} ,{addressItem.landmark} ,
                      {addressItem.pinCode} ,
                    </React.Fragment>
                  ))}
                </p>
                <p className="py-4">
                  <FaRegClock fontSize={"28px"} />{" "}
                  <span style={{ color: "#de3c3c" }}>Open now</span>
                  {"  "}
                  {vendorData?.vendor?.startTime} -{" "}
                  {vendorData?.vendor?.endTime}
                </p>
                <p className="py-4">
                  <MdPayment fontSize={"28px"} /> Payment option{" - "}
                  {vendorData?.vendor?.paymentType?.map(
                    (elem, index, array) => (
                      <React.Fragment key={index}>
                        {elem}
                        {index !== array.length - 1 && " , "}
                      </React.Fragment>
                    )
                  )}
                </p>
                <p className="py-4">
                  <FaCalendarDay fontSize={"28px"} /> Year of Establish -{" "}
                  {vendorData?.vendor?.yearOfEsteblish}
                </p>
                <p className="py-4">
                  <FaGlobe fontSize={"28px"} /> {vendorData?.vendor?.email}
                </p>
                <ul class="list-unstyled mb-0 d-flex align-items-center justify-content-center">
                  <li class="d-flex align-items-center ">
                    <Link to={vendorData?.links?.twitter}>
                      <img
                        style={{ width: "40px", height: "40px" }}
                        src={twitter}
                        alt="twitter-icon"
                      ></img>
                    </Link>
                  </li>
                  <li class="d-flex align-items-center ">
                    <Link to={vendorData?.links?.linkedin}>
                      <img
                        style={{ width: "40px", height: "40px" }}
                        src={linkedin}
                        alt="linkedin-icon"
                      ></img>
                    </Link>
                  </li>
                  <li class="d-flex align-items-center ">
                    <Link to={vendorData?.links?.facebook}>
                      <img
                        style={{ width: "40px", height: "40px" }}
                        src={facebook}
                        alt="facebook-icon"
                      ></img>
                    </Link>
                  </li>
                  <li class="d-flex align-items-center ">
                    <Link to={vendorData?.links?.instagram}>
                      <img
                        style={{ width: "40px", height: "40px" }}
                        src={instagram}
                        alt="instagram-icon"
                      ></img>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="worker-details">
                <div className="d-flex justify-content-between">
                  {" "}
                  <h6>Photos</h6>
                  <h5 onClick={handleImgLength} style={{ color: "#de3c3c" }}>
                    See all photos
                  </h5>
                </div>
                <hr />
                <div className="d-flex justify-content-around">
                  <div className="worker-details position-relative">
                    <div className="row">
                      {" "}
                      {vendorData?.gallary
                        ?.slice(0, imgLength)
                        ?.map((elem, id) => {
                          return (
                            <div className="col-md-4 col-6">
                              <img
                                src={elem?.image}
                                className="vendor_list_img"
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7 vendor_profile_scroll">
              <div className="worker-details">
                <h6>Documents</h6>
                <hr />
                <div className="d-flex justify-content-around mt-5">
                  <div className="worker-details position-relative">
                    <img src={vendorData?.vendor?.aadharImage} />

                    <p className=" text-center">Aadhar Card</p>
                    {vendorData?.vendor?.aadharImage && (
                      <div className="verified-icon">
                        <MdVerified fontSize={"40px"} color="green" />
                      </div>
                    )}
                  </div>
                  <div className="worker-details position-relative">
                    <img src={vendorData?.vendor?.panImage} />
                    <p className=" text-center">Pan Card</p>
                    {vendorData?.vendor?.panImage && (
                      <div className="verified-icon">
                        <MdVerified fontSize={"40px"} color="green" />
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-around mt-5 mb-2">
                  <div className="worker-details position-relative">
                    <img src={vendorData?.vendor?.otherDocumentImage} />
                    <p className=" text-center">Other document</p>
                    {vendorData?.vendor?.otherDocumentImage && (
                      <div className="verified-icon">
                        <MdVerified fontSize={"40px"} color="green" />
                      </div>
                    )}
                  </div>
                  <div className="worker-details position-relative">
                    <img src={vendorData?.vendor?.companyCertificateImage} />
                    <p className=" text-center">Company Certificate</p>
                    {vendorData?.vendor?.companyCertificateImage && (
                      <div className="verified-icon">
                        <MdVerified fontSize={"40px"} color="green" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="worker-details"
                style={{ backgroundColor: "#f3f1f1" }}
              >
                <h6>User Reviews</h6>
                <hr />
                {vendorData?.reviews?.reviewsData
                  ?.slice(0, reviewLength)
                  ?.map((elem, id) => {
                    return (
                      <>
                        <div className=" d-flex justify-content-between">
                          <div className="d-flex user_reviews">
                            <img src={elem?.userId?.profile_image} />
                            <div>
                              <h6>{elem?.userId?.name}</h6>
                              <p>
                                {elem?.rating}{" "}
                                {Array.from({ length: elem.rating }).map(
                                  (elem, id) => {
                                    return (
                                      <FaStar
                                        style={{
                                          fontSize: "20px",
                                          color: "#ffc107",
                                        }}
                                      />
                                    );
                                  }
                                )}
                              </p>
                            </div>
                          </div>
                          <div>
                            <p>22 JUN 2022</p>
                            <p>10 AM</p>
                          </div>
                        </div>
                        <div>
                          <p>{elem?.review}</p>
                        </div>
                      </>
                    );
                  })}
                <div className="d-flex justify-content-center">
                  <button className="all_reviews_btn" onClick={handleReviews}>
                    Read all reviews
                  </button>
                </div>
              </div>
              <div className="worker-details">
                <h6>Service Provider In</h6>
                <hr />
                {vendorData?.totalServices?.map((elem, id) => {
                  return (
                    <ul>
                      <li style={{ listStyle: "none" }}>{elem}</li>
                    </ul>
                  );
                })}
              </div>
              <div className="worker-details">
                <h6>About Us</h6>
                <hr />
                <p>
                  Meet our exceptional service worker{" "}
                  {vendorData?.vendor?.companyName} , dedicated to elevating
                  your experience with unparalleled expertise and a commitment
                  to excellence. With a passion for precision and a keen eye for
                  detail, our service worker goes above and beyond to ensure
                  that every task is completed with utmost care.
                  {vendorData?.vendor?.companyName} professional is not just a
                  worker; they are a problem solver, a meticulous executor, and
                  a friendly face all rolled into one. Whether it's servicing
                  your appliances, maintaining your equipment, or addressing any
                  concerns you may have, our service worker is your reliable
                  partner in keeping things running seamlessly. With a wealth of
                  knowledge and hands-on experience, our service worker combines
                  technical proficiency with a customer-centric approach. Their
                  goal is to not only meet but exceed your expectations, leaving
                  you with the peace of mind that comes from knowing your
                  belongings are in expert hands. From routine maintenance to
                  unexpected repairs, our service worker is always ready to step
                  in and deliver top-notch service that reflects our unwavering
                  commitment to your satisfaction. Choose excellence, choose our
                  service worker – where every service is a testament to our
                  dedication to quality and customer delight
                </p>
              </div>
            </div>
          </div>

          <hr />
          <div className="row justify-content-around">
            {/* <div className="col-md-5">
             
            </div> */}
            {/* <div className="col-md-7">
              
            </div> */}
            {/* <div className="col-md-5"></div> */}

            {/* <div className="col-md-5"></div> */}
            {/* <div className="col-md-7">
              
            </div> */}
          </div>
        </div>
      </div>
      <TopVendors />
      {/* <TextPage /> */}
      <div className="container">
        <p>
          Meet our exceptional service worker {vendorData?.vendor?.companyName}{" "}
          , dedicated to elevating your experience with unparalleled expertise
          and a commitment to excellence. With a passion for precision and a
          keen eye for detail, our service worker goes above and beyond to
          ensure that every task is completed with utmost care.
          {vendorData?.vendor?.companyName} professional is not just a worker;
          they are a problem solver, a meticulous executor, and a friendly face
          all rolled into one. Whether it's servicing your appliances,
          maintaining your equipment, or addressing any concerns you may have,
          our service worker is your reliable partner in keeping things running
          seamlessly. With a wealth of knowledge and hands-on experience, our
          service worker combines technical proficiency with a customer-centric
          approach. Their goal is to not only meet but exceed your expectations,
          leaving you with the peace of mind that comes from knowing your
          belongings are in expert hands. From routine maintenance to unexpected
          repairs, our service worker is always ready to step in and deliver
          top-notch service that reflects our unwavering commitment to your
          satisfaction. Choose excellence, choose our service worker – where
          every service is a testament to our dedication to quality and customer
          delight
        </p>
      </div>
      <ReachEmployers />
      <Footer />

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="p-4"
      >
        <Modal.Body>
          <div closeButton style={{ borderBottom: "3px solid #D2021C" }}>
            <div
              style={{ position: "absolute", right: "3%", fontSize: "22px" }}
              onClick={handleFalse}
            >
              {" "}
              <RxCross2 />
            </div>
            <Modal.Title id="contained-modal-title-vcenter" className="p-4">
              <h6>Best Deals</h6>
              <h5>Add your best deal name</h5>
            </Modal.Title>
          </div>
          <Form className="p-4 ">
            <div className="row ">
              <div className="col-md-12 ">
                <Form.Group className="input-box" controlId="">
                  <Form.Control
                    type="text"
                    placeholder="Enter Your  name"
                    name="name"
                    value={deal.name}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-6 ">
                <Form.Group className="input-box" controlId="">
                  <Form.Control
                    type="number"
                    placeholder="Enter Your mobile no"
                    name="mobile"
                    value={deal.mobile}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="input-box" controlId="">
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Enter your email Id"
                    value={deal.email}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
            </div>

            <div className="row ">
              <div className="col-md-12 ">
                <Form.Group
                  className="input-box"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    as="textarea"
                    rows={8}
                    placeholder="Enter your query"
                    name="query"
                    value={deal.query}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
            </div>
          </Form>
        </Modal.Body>

        <div className="text-center mb-5">
          <button className="category-btn" onClick={handleQuery}>
            Submit
          </button>
        </div>
        {/* <Button onClick={handleFalse}>Close</Button> */}
      </Modal>
      <Loader loading={loader} />
      <Login 
      modalShow={modalShow1}
      modalShow2={LoginModal}
      setModalShow={setModalShow1}
      setModalShow2={setLoginModal}
      />
    </>
  );
};

export default VendorProfile;
