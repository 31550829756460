import React, { useEffect, useState } from "react";
import VendorNavbar from "./VendorNavbar";
import banner from "../../Assets/banner.svg";
import { Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import TextPage from "../LandingPageComponent/TextPage";
import Footer from "../LandingPageComponent/Footer";
import ReachEmployers from "../LandingPageComponent/ReachEmployers";
import VendorAllTab from "./VendorAllTab";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { onGetCatrgoryByServicId } from "../Action/FetchAction";
import Loader from "../Loader/Loader";
import DataNotFound from "../Loader/DataNotFound";
import Login from "../Dialogbox/Login";
import Cookies from "js-cookie";

const VendorList = () => {
  const dispatch = useDispatch()
  const location = useLocation();
  const categoryId = location.state.categoryId;
  const userArea = Cookies.get("userArea")

  const [category , setCategory] = useState([])
  const [loader , setLoader] = useState(false)


  useEffect(() => {
    setLoader(true)
    let data = {
      id: categoryId,
      sort:["All"],
      location:userArea
    };
    dispatch(onGetCatrgoryByServicId(data, setCategory , setLoader));
  }, []);




  return (
    <>
      <VendorNavbar location={location}/>
      <div className="banner-vendor_list_img">
        <img src="" />
      </div>
      <Container fluid>
        <div className="row justify-content-center">
          <div className="col-md-10">
            <h3 className="py-5">{location?.state?.location}/{location?.state?.services}</h3>
            <div className="row mb-5">
              <div className="col-md-4 list-category-box">
                <Form.Select aria-label="Default select example">
                  <option>Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>

                <Form.Select aria-label="Default select example">
                  <option>Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>

                <Form.Select aria-label="Default select example">
                  <option>Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>

                <Form.Select aria-label="Default select example">
                  <option>Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>

                <Form.Select aria-label="Default select example">
                  <option>Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>

                <Form.Select aria-label="Default select example">
                  <option>Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </div>

              <div className="col-md-8 text-end">
                {category?<VendorAllTab category={category} location={location}/>:<DataNotFound/>}
              </div>
            </div>
          </div>
        </div>
      </Container>
      <TextPage />
      <ReachEmployers />
      <Footer />
      <Loader loading={loader}/>
      
    </>
  );
};

export default VendorList;
