import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { onSendAdvertisingDetails, onSendBusinessDetails } from "../Action/FetchAction";

const Advertising = () => {

  const dispatch = useDispatch()

  const [business , setBusiness] = useState({
    name:"",
    number:"",
    businessName:"",
    city:"",
    pinCode:""
  })

  const handleChange=(e)=>{
    const {name , value} = e.target;
   
      setBusiness({
        ...business,
        [name]:value
      })
  }

  const handleSubmit=(e)=>{
    e.preventDefault()
    dispatch(onSendAdvertisingDetails(business , setBusiness , business))
  }
  return (
    <><div className="bg-box p-4">
    <h3 className="py-4">Apply for advertising</h3>
    <h6 className="py-4">Please enter your Information</h6>
    <hr />
    <Form className="p-4 " onSubmit={handleSubmit}>
      <div className="row ">
        <div className="col-md-6 ">
        <Form.Label><p className="m-0">Business</p></Form.Label>
          <Form.Group className="input-box" controlId="">
            <Form.Control type="text" placeholder="Enter Your first name" 
            onChange={handleChange}
             value={business.businessName}
             name='businessName'
             />
          </Form.Group>
        </div>
        <div className="col-md-6">
            <Form.Label><p className="m-0">Full Name</p></Form.Label>
          <Form.Group className="input-box" controlId="">
            <Form.Control type="text" placeholder="Enter your last name" 
            onChange={handleChange}
             value={business.name}
             name='name'
             />
          </Form.Group>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-6 ">
            <Form.Label><p className="m-0">Mobile No</p></Form.Label>
          <Form.Group className="input-box" controlId="">
            <Form.Control type="text" placeholder="Enter Your mobile no" 
            onChange={handleChange}
             value = {business.number}
             name='number'
             />
          </Form.Group>
        </div>
        <div className="col-md-6">
            <Form.Label><p className="m-0">Pin Code</p></Form.Label>
          <Form.Group className="input-box" controlId="">
            <Form.Control type="text" placeholder="Enter your email Id" 
            onChange={handleChange}
             value={business.pinCode} 
             name='pinCode'
             />
          </Form.Group>
        </div>
      </div>

      <div className="row ">
      <div className="col-md-12 ">
                <Form.Group
                  className="input-box"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    as="textarea"
                    rows={8}
                    placeholder="Enter your City"
                    value={business.city}
                     onChange={handleChange}
                     name="city"
                  />
                </Form.Group>
              </div>
      </div>
      <div className="text-center mb-5">
        <button className="category-btn" type="submit">Save</button>
      </div>
    </Form>
  </div>

  </>
  );
};

export default Advertising;
