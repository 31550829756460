import React from "react";
import Header from "../LandingPageComponent/Header/Header";
import Header1 from "../LandingPageComponent/Header/Header1";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import MyDetails from "./MyDetails";
import ListBusiness from "./ListBusiness";
import Advertising from "./Advertising";
import Notification from "./Notification";
import ReachEmployers from "../LandingPageComponent/ReachEmployers";
import Footer from "../LandingPageComponent/Footer";
import AboutUs from "./AboutUs";
import Privacy from "./Privacy";
import TermCondition from "./TermCondition";
import { onLogoutUser } from "../Action/AuthAction";
import { useDispatch } from "react-redux";
import { AiOutlineLogout } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const MyAccountPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleLogout=()=>{
    dispatch(onLogoutUser(false,navigate))
  }
  return (
    <>
      <Header />
      <Header1 />

      <div className="row justify-content-center" style={{backgroundColor:"#F9F9F9"}}>
        <div className="col-md-10">
          <h1 className="mt-5">My Account</h1>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first" >
            <Row className="justify-content-between mt-5">
              <Col sm={3}>
                <Nav variant="pills" className="flex-column ">
                  <Nav.Item className="account-nav-pills cursor-pointer">
                    <Nav.Link  eventKey="first">My Details</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="account-nav-pills cursor-pointer">
                    <Nav.Link  eventKey="second">List Your business</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="account-nav-pills cursor-pointer">
                    <Nav.Link  eventKey="third">Advertising</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="account-nav-pills cursor-pointer">
                    <Nav.Link  eventKey="four">Notification</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="account-nav-pills cursor-pointer">
                    <Nav.Link  eventKey="fifth">About Us</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="account-nav-pills cursor-pointer">
                    <Nav.Link  eventKey="sixth">Privacy and Policy</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="account-nav-pills cursor-pointer">
                    <Nav.Link  eventKey="seventh">Term and Condition</Nav.Link>
                  </Nav.Item>
                </Nav>
                <div className="d-flex align-items-center mt-5">
            <AiOutlineLogout style={{fontSize:"30px" , marginRight:"10px"}}/>
            <h3 style={{color:"#dc2c1d"}} onClick={handleLogout}>Log Out</h3>
          </div>
              </Col>
              <Col sm={9} className="mb-4">
                <Tab.Content>
                  <Tab.Pane eventKey="first"><MyDetails/></Tab.Pane>
                  <Tab.Pane eventKey="second"><ListBusiness/></Tab.Pane>
                  <Tab.Pane eventKey="third"><Advertising/></Tab.Pane>
                  <Tab.Pane eventKey="four"><Notification/></Tab.Pane>
                  <Tab.Pane eventKey="fifth"><AboutUs/></Tab.Pane>
                  <Tab.Pane eventKey="sixth"><Privacy/></Tab.Pane>
                  <Tab.Pane eventKey="seventh"><TermCondition/></Tab.Pane>

                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
      <ReachEmployers />
      <Footer />
    </>
  );
};

export default MyAccountPage;
