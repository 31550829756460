import React from "react";
import Header1 from "./LandingPageComponent/Header/Header1";
import Header from "./LandingPageComponent/Header/Header";
import BannerPage from "./LandingPageComponent/BannerPage";
import TopCategories from "./LandingPageComponent/TopCategories";
import TopVendors from "./LandingPageComponent/TopVendors";
import TopServices from "./LandingPageComponent/TopServices";
import BlogPage from "./LandingPageComponent/BlogPage";
import TextPage from "./LandingPageComponent/TextPage";
import Footer from "./LandingPageComponent/Footer";
import ReachEmployers from "./LandingPageComponent/ReachEmployers";
import { useNavigate } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa6";

const LandingPage = () => {
  const navigate = useNavigate()
  return (
    <>
      <Header />
      <Header1 />
      <BannerPage />
      <TopCategories />
      <TopServices />
      <TopVendors />
      <BlogPage />
      <TextPage />
      <ReachEmployers />
      <Footer />
      <a href="https://api.whatsapp.com/send?phone=+917836967003&text=Hi%20I%20Found%20Your%20Business%20On%20Homeshiftingmart" navigate="blank" className="float">
      <FaWhatsapp className="my-float" />
      </a>
    </>
  );
};

export default LandingPage;
