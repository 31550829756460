import React ,{useState}from 'react'
import { Container, Form } from 'react-bootstrap'
import Modal from "react-bootstrap/Modal";
import { RxCross2 } from "react-icons/rx";

const ReachEmployers = () => {

  const [modalShow2, setModalShow2] = useState(false);

  const [advertising , setAdvertising] = useState({
    name :"",
    mobile:"",
    email:"",
    business:"",
    exp:"",
    address:""
})

  const handleFalse2 = () => {
    setModalShow2(false);
  };
  const handleTrue2 = () => {
    setModalShow2(true);
  };

  const handleChange = (type, e) => {
    const { name, value } = e.target;
        setAdvertising({
            ...advertising,
            [name]: value,
          });
   
  };
  return (
    <Container fluid className='mb-5 mt-5'>
    <div className='row justify-content-center'>
        <div className='col-md-10'>
        <div className="ad-box">
            <div className="row">
                <div className="col-md-8">
                    <h1>We will help you out to reach employers</h1>
                    <p> aliquam aliquid ea quaerat necessitatibus. Dolor nostrum atque quibusdam labore eligendi nam eius eum in suscipit.</p>
                </div>
                <div className="col-md-4"><button className="category-btn" onClick={handleTrue2}>Register with us | Email Id</button></div>
            </div>
          </div>
        </div>
    </div>
    <Modal
        show={modalShow2}
        onHide={() => setModalShow2(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="p-4"
      >
        <Modal.Body>
          <div closeButton style={{ borderBottom: "3px solid #D2021C" }}>
            <div
              style={{ position: "absolute", right: "3%", fontSize: "22px" }}
              onClick={handleFalse2}
            >
              {" "}
              <RxCross2 />
            </div>
            <Modal.Title id="contained-modal-title-vcenter" className="p-4">
              <div className="text-center">
                <h6>Register your business for free India's leading search engine </h6>
              </div>
            </Modal.Title>
          </div>
          <Form className="p-4 ">
            <h6 className="text-center">Fill your details</h6>
            <div className="row ">
              <div className="col-md-6 ">
                <Form.Group className="input-box" controlId="">
                  <Form.Control
                    type="text"
                    placeholder="Enter Your name"
                    value={advertising.name}
                    name="fName"
                    onChange={(e) => handleChange("advert", e)}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6 align-items-center d-flex ">
                <Form.Select
                  aria-label="Default select example"
                  name="gender"
                  onChange={(e) => handleChange("advert", e)}
                >
                  <option>Choose your gender</option>
                  <option value="1">Male</option>
                  <option value="2">Female</option>
                </Form.Select>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-6 ">
                <Form.Group className="input-box" controlId="">
                  <Form.Control
                    type="text"
                    value={advertising.mobile}
                    placeholder="Enter Your mobile no"
                    onChange={(e) => handleChange("advert", e)}
                    name="mobileNo"
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="input-box" controlId="">
                  <Form.Control
                    type="text"
                    placeholder="Enter your email Id"
                    value={advertising.email}
                    onChange={(e) => handleChange("advert", e)}
                    name="email"
                  />
                </Form.Group>
              </div>
            </div>

            <div className="row ">
              <div className="col-md-6 ">
              <Form.Group className="input-box" controlId="">
                  <Form.Control
                    type="text"
                    placeholder="year of experiences"
                    value={advertising.exp}
                    onChange={(e) => handleChange("advert", e)}
                    name="exp"
                  />
                </Form.Group>
              </div>
              <div className="col-md-6 ">
              <Form.Group className="input-box" controlId="">
                  <Form.Control
                    type="text"
                    placeholder="Enter your business name"
                    value={advertising.business}
                    onChange={(e) => handleChange("advert", e)}
                    name="business"
                  />
                </Form.Group>
              </div>
            </div>
          </Form>
        </Modal.Body>

        <div className="text-center mb-5">
          <button className="category-btn" >
            Submit
          </button>
        </div>
        {/* <Button onClick={handleFalse}>Close</Button> */}
      </Modal>
  </Container>
  )
}

export default ReachEmployers