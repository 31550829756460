import Offcanvas from "react-bootstrap/Offcanvas";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { CgProfile } from "react-icons/cg";
import { FaBusinessTime } from "react-icons/fa";
import { RiAdvertisementFill } from "react-icons/ri";
import { IoMdNotifications } from "react-icons/io";
import { MdPolicy } from "react-icons/md";
import { BsTerminal } from "react-icons/bs";
import { AiOutlineLogout } from "react-icons/ai";
import { onLogoutUser, onUpdateUser } from "../../Action/AuthAction";
import { useNavigate } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import logo from "../../../Assets/logo.png";
import { useEffect, useState } from "react";

const list = [
  {
    name: "My Details ",
    icons: <CgProfile  fontSize={"22px"} style={{ marginRight: "10px"}}/>,
  },
  {
    name: "List your business ",
    icons: <FaBusinessTime  fontSize={"22px"} style={{ marginRight: "10px"}}/>,
  },
  {
    name: "Advertising",
    icons: <RiAdvertisementFill  fontSize={"22px"} style={{ marginRight: "10px"}}/>,
  },

  {
    name: "Notification ",
    icons: <IoMdNotifications  fontSize={"22px"} style={{ marginRight: "10px"}}/>,
  },
  {
    name: "About us",
    icons: <CgProfile  fontSize={"22px"} style={{ marginRight: "10px"}}/>,
  },
  {
    name: "Term and Condition",
    icons: <MdPolicy fontSize={"22px"} style={{ marginRight: "10px"}} />,
  },
  {
    name: "Privacy and Policy",
    icons: <BsTerminal fontSize={"22px"} style={{ marginRight: "10px"}} />,
  },
];

const OffCanvas = ({ offCanvas, setOffcanvas }) => {
  const userData = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [modalShow1, setModalShow1] = useState(false);
  const [signup, setSignup] = useState({
    fName: "",
    // lName: "",
    email: "",
    mobileNo: "",
    gender: "",
    img: "",
  });

  const handleFalse1 = () => {
    setModalShow1(false);
  };
  const handleTrue1 = () => {
    setModalShow1(true);
  };

  const handleUpdateUser = () => {
    const formdata = new FormData();
    formdata.append("email", signup.email);
    formdata.append("name", signup.fName);
    formdata.append("number", signup.mobileNo);
    formdata.append("Image", signup.img);
    formdata.append("gender", signup.gender === "1" ? "male" : "female");

    dispatch(onUpdateUser(formdata, handleFalse1 , handleLogout));
  };

  useEffect(()=>{
    setSignup({
      ...signup,
      fName:userData?.user?.name,
      email:userData?.user?.email,
      mobileNo:userData?.user?.mobile_number,
      gender:userData?.user?.gender==="male"?"1":"2",
      img:userData?.user?.profile_image
    }) 
  },[modalShow1])
  

  const handleClose = () => setOffcanvas(false);

  const handleChange = (type, e) => {
    const { name, value } = e.target;
      if (name === "img") {
        const file = e.target.files[0];
        setSignup({
          ...signup,
          img: file,
        });
      } else {
        setSignup({
          ...signup,
          [name]: value,
        });
    }
  };

  const handleLogout=()=>{
    dispatch(onLogoutUser(handleClose , navigate))
  }

  return (
    <>
      {/* Move Offcanvas component here */}
      <Offcanvas
        show={offCanvas}
        onHide={handleClose}
        placement="end"
        name="Enable backdrop (default)"
        className="p-4"
      >
        <Offcanvas.Header>
          <Offcanvas.Title>{userData?.user?.name}</Offcanvas.Title><br/>
         
          <img
            src={userData?.user?.profile_image}
            height={"60px"}
            width={"60px"}
            style={{ borderRadius: "50%", margin: "auto" }}
            alt="User Profile"
          />
        </Offcanvas.Header>
        <div style={{position:"absolute",top:"8%", left:'6%'}} onClick={handleTrue1}> <p>View my profile</p></div>
        <Offcanvas.Body>
          {list.map((elem, id) => {
            return (
              <li onClick={()=>navigate("/my-account")} style={{ listStyle: "none", padding: "15px 0px" }}>
                {elem.icons}
                {elem.name}
              </li>
            );
          })}
          <div className="d-flex align-items-center mt-5">
            <AiOutlineLogout style={{fontSize:"30px" , marginRight:"10px"}}/>
            <h3 style={{color:"#dc2c1d"}} onClick={handleLogout}>Log Out</h3>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal
        show={modalShow1}
        onHide={() => setModalShow1(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="p-4"
      >
        <Modal.Body>
          <div closeButton style={{ borderBottom: "3px solid #D2021C" }}>
            <div
              style={{ position: "absolute", right: "3%", fontSize: "22px" }}
              onClick={handleFalse1}
            >
              {" "}
              <RxCross2 />
            </div>
            <Modal.Title id="contained-modal-title-vcenter" className="p-4">
              <div className="text-center">
                <img src={logo} />
              </div>
            </Modal.Title>
          </div>
          <Form className="p-4 ">
            <h6 className="text-center">Fill your details</h6>
            <div className="row ">
              <div className="col-md-6 ">
                <Form.Group className="input-box" controlId="">
                  <Form.Control
                    type="text"
                    placeholder="Enter Your name"
                    value={signup.fName}
                    name="fName"
                    onChange={(e) => handleChange("signup", e)}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6 align-items-center d-flex ">
                <Form.Select
                  aria-label="Default select example"
                  name="gender"
                  onChange={(e) => handleChange("signup", e)}
                >
                  <option>Choose your gender</option>
                  <option value="1">Male</option>
                  <option value="2">Female</option>
                </Form.Select>
              </div>
              {/* <div className="col-md-6">
                <Form.Group className="input-box" controlId="">
                  <Form.Control
                    type="text"
                    placeholder="Enter your last name"
                    value={signup.lName}
                    onChange={(e) => handleChange("signup", e)}
                    name="lName"
                  />
                </Form.Group>
              </div> */}
            </div>
            <div className="row ">
              <div className="col-md-6 ">
                <Form.Group className="input-box" controlId="">
                  <Form.Control
                    type="text"
                    value={signup.mobileNo}
                    placeholder="Enter Your mobile no"
                    onChange={(e) => handleChange("signup", e)}
                    name="mobileNo"
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="input-box" controlId="">
                  <Form.Control
                    type="text"
                    placeholder="Enter your email Id"
                    value={signup.email}
                    onChange={(e) => handleChange("signup", e)}
                    name="email"
                  />
                </Form.Group>
              </div>
            </div>

            <div className="row ">
              <div className="col-md-6 ">
                <Form.Group className="input-box" controlId="">
                  <Form.Control
                    type="file"
                    accept="png/jpg/jpeg"
                    onChange={(e) => handleChange("signup", e)}
                    name="img"
                  />
                </Form.Group>
              </div>
              <div className="col-md-6 ">
               <img src={signup.img} width={"100px"}/>
              </div>
            </div>
          </Form>
        </Modal.Body>

        <div className="text-center mb-5">
          <button className="category-btn" onClick={handleUpdateUser}>
            Update
          </button>
        </div>
        {/* <Button onClick={handleFalse}>Close</Button> */}
      </Modal>
    </>
  );
};

export default OffCanvas;
