import { combineReducers } from "redux";
import { AuthReducer } from "./AuthReducer";
import { FetchReducer } from "./FetchReducer";
// import { EventReducer } from "./EventReducer";

const rootReducer = combineReducers({
     auth:AuthReducer,
    fetch:FetchReducer
});

export default rootReducer;