import React, { useState } from 'react'
import Modal from "react-bootstrap/Modal";
import logo from "../../Assets/logo.png";
import { Form } from 'react-bootstrap';
import { RxCross2 } from 'react-icons/rx';
import { onLoginUser, onVerifyOtp } from '../Action/AuthAction';
import { useDispatch } from 'react-redux';
import OtpInput from "react-otp-input";


const Login = ({modalShow, modalShow2 ,setModalShow , setModalShow2}) => {
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

    const [loginNo,setloginNo] =useState("")
    
    const [code, setCode] = useState("");
  

    const handleFalse = () => {
        setModalShow(false);
      };
      

      const handleFalse2 = () => {
        setModalShow2(false);
      };
      const handleTrue2 = () => {
        setModalShow2(true);
      };

      const handleChangeOtp = (code) => setCode(code);

      const handleLogin = () => {
        const data = {
          number: loginNo,
        };
        dispatch(onLoginUser(data, setLoader, handleTrue2, handleFalse));
      };

      const handleContinue = () => {
        const data = {
          number: loginNo,
          otp: code,
        };
        dispatch(onVerifyOtp(data, setLoader, true, handleFalse2));
      };

  return (
    <div>  
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className=""
    >
      <Modal.Body>
        <div closeButton style={{ borderBottom: "3px solid #D2021C" }}>
          <div
            style={{ position: "absolute", right: "3%", fontSize: "22px" }}
            // onClick={handleFalse}
          >
            {" "}
            <RxCross2 />
          </div>
          <Modal.Title id="contained-modal-title-vcenter" className="p-4">
            <div className="text-center">
              <img src={logo} />
            </div>
          </Modal.Title>
        </div>
        <Form className="p-4 ">
          <h6 className="text-center">Welcome for seamless experience</h6>
          <div className="row justify-content-center">
            <div className="col-md-8 ">
              <p className="text-center">Enter your Mobile No.</p>
              <Form.Group className="input-box" controlId="">
                <Form.Control
                  type="text"
                  placeholder="Enter Your mobile no"
                  value={loginNo}
                  onChange={(e) => setloginNo(e.target.value)}
                  name="loginNo"
                />
              </Form.Group>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <div className="text-center mb-5">
          <button className="category-btn"
           onClick={handleLogin}
           >
            Login with OTP
          </button>
        </div>
      </Modal>
      {/* OOOOOOOOOOOTTTTTTTTTTTTTTTTTPPPPPPPPPPPPPPPPPPPPP */}

      <Modal
    show={modalShow2}
    onHide={() => setModalShow2(false)}
    size="md"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    className=""
  >
    <Modal.Body>
      <div closeButton style={{ borderBottom: "3px solid #D2021C" }}>
        <div
          style={{ position: "absolute", right: "3%", fontSize: "22px" }}
          onClick={handleFalse2}
        >
          {" "}
          <RxCross2 />
        </div>
        <Modal.Title id="contained-modal-title-vcenter" className="p-4">
          <div className="text-center">
            <img src={logo} />
          </div>
        </Modal.Title>
      </div>
      <Form className="p-4 ">
        <h6 className="text-center">Welcome for seamless experience</h6>
        <div className="row justify-content-center">
          <div className="col-md-8 ">
            <p className="text-center">Enter the code sent to </p>
            <OtpInput
              value={code}
              onChange={handleChangeOtp}
              numInputs={4}
              separator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
              isInputNum={true}
              shouldAutoFocus={true}
              inputStyle={{
                border: "1px solid #000",
                borderRadius: "8px",
                width: "54px",
                height: "54px",
                fontSize: "16px",
                color: "#000",
                fontWeight: "400",
                caretColor: "blue",
                margin: "auto",
              }}
              focusStyle={{
                border: "1px solid #CFD3DB",
                outline: "none",
              }}
            />
          </div>
        </div>
      </Form>
    </Modal.Body>

    <div className="text-center mb-5">
      <button className="category-btn" onClick={handleContinue}>
        Continue
      </button>
    </div>
  </Modal>
      </div>
  )
}

export default Login