import { produce } from "immer";

const initialState = {};

export const FetchReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "USER_LOCATION_SERVICES":
      return produce(state, (draft) => {
        draft.locationServices = payload;
      });

    default:
      return state;
  }
};