import React from "react";
import Header from "./LandingPageComponent/Header/Header";
import Header1 from "./LandingPageComponent/Header/Header1";
import Footer from "./LandingPageComponent/Footer";

const AboutUs = () => {
  return (
    <div>
      <Header />
      <Header1 />
      <div className="row justify-content-center py-5">
        <div className="col-md-10 ">
        <h3 className="py-4">About Us</h3>
        <hr />
        <div class="container">
          <div>
            <h3>
              <p>
                <h6>About Us&nbsp;</h6>
              </p>

              <p>
                Home Shifting Mart is a registered leading logistics service
                provider in India with CIN- U60200DL2022PTC396447. We are the
                organisation that will fulfil all your Relocation service
                nationwide and internationally. We have a good network that
                spreads across many cities everywhere in India. We use the most
                effective and the most practised individuals partnering with us.
                Be it your workplace or house, we build every issue with tons of
                tenderness and toil. Home Shifting Mart is cognizant of this
                truth and guarantees 100 percent reliable clients both for
                logistics and packers-movers services. We select the partners of
                10/10 rating for all logistics services.
              </p>

              <p>
                <h6>We offer :</h6>
              </p>

              <ul>
                <li>
                  Residential and workplace relocation (from city to totally
                  different places of India) service provider.
                </li>
                <li>Industrial Shifting service provider</li>
                <li>
                  Shifting and clearances for home and workplace appliances
                  service provider
                </li>
                <li>Cargo/Goods packing and transporting service provider</li>
                <li>Warehouse Facility</li>
              </ul>

              <p>
                <h6>Who are Home Shifting Mart?</h6>
              </p>

              <p>
                Home Shifting Mart is a technology-driven company focussed on
                providing quality, reliable logistics service provider across
                the globe. We cater to native, domestic and international
                logistics services necessities. We have selected out the best
                logistics service provider in your area at affordable
                price.&nbsp; We offer native shifting, domestic shifting,
                storage, packing, bike and automobile transportation services
                provider in India. We act as a connecting link between customers
                having packing, moving, logistics, or storage desires with firms
                close to your location, providing those at real rates. We
                deliver a high-level quality packing and moving service provider
                that systematically meets our customer's desires and exceeds
                expectations. Home Shifting Mart is a web search portal for
                locating skilled and reliable relocation service suppliers.
                We've listed and organized all relocation firms for your
                convenience per low cost and best service suppliers.
              </p>

              <p>
                <h6>Why Home Shifting Mart?</h6>
              </p>

              <ul>
                <li>
                  We provide the list of vendors, that are selected after a
                  thorough process.
                </li>
                <li>Our vendors are 100% reliable</li>
                <li>
                  We provide service list with vendor to check your requirements
                </li>
                <li>
                  Our services are less costly than other or individual hiring
                  of a service provider
                </li>
                <li>Services are available in all over India</li>
                <li>Vendor Ratings and reviews are available for customers</li>
                <li>Fast and reliable service</li>
                <li>24*7 Service</li>
                <li>Free consultation for shifting and logistics services</li>
              </ul>

              <p>
                <h6>Our Mission</h6>
              </p>

              <p>
                Quality and client satisfaction is our priority, and there's
                internal control and management at every step of the service
                provides. We offer a free, truthful and cheap service providers
                for all types of logistics services. With our A-grade services,
                we want to become the world's leading logistics service
                provider. Aside from that, our consumers are our top concern,
                and we work hard to earn their confidence and faith.
              </p>
            </h3>
          </div>
        </div>
      </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
