import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import downloadapp from "../../Assets/download_app.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCategories, onSearchServices } from "../Action/FetchAction";
import Cookies from "js-cookie";
import Loader from "../Loader/Loader";

const TopCategories = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userArea = Cookies.get("userArea")
  const [category, setCategory] = useState([]);
  const [categoryLength, setCategoryLength] = useState(8);
 const [loader , setLoader] = useState(false)

  const locationServices = useSelector((state) => state.fetch);

  useEffect(() => {
    setLoader(true)
    dispatch(fetchAllCategories(setCategory , setLoader));
  }, []);

  const handleCategory = (id , name) => {
    navigate('/sub-category' , {state:{categoryId:id , location: userArea, services: name,}})
  };

  const handleAllService=()=>{
    setCategoryLength(category.length)
  }
  const handleLessService=()=>{
    setCategoryLength(8)
  }
  return (
    <Container fluid className="mt-5">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <div className="text-center">
            <h1>Top Popular Categories</h1>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Aspernatur iusto nemo fugiat beatae deleniti natus praesentium
              placeat delectus? Voluptate natus dolor quisquam odit.{" "}
            </p>
          </div>
          <div className="row justify-content-center p-4">
            {category?.allServices?.slice(0,categoryLength)?.map((elem, id) => {
              return (
                <>
                  <div
                    className="col-md-3 col-sm-4 col-3 category-img-div"
                    onClick={() => handleCategory(elem._id , elem.name)}
                  >
                    <img src={elem.image} />
                    <h5>{elem.name}</h5>
                  </div>
                </>
              );
            })}
          </div>
          <div className="text-center p-5">
           {categoryLength===8? <button className="category-btn" onClick={handleAllService}>ALL SERVICES</button>:
            <button className="category-btn" onClick={handleLessService}>LESS SERVICES</button>
           }
          </div>
        </div>
        
      </div>
      <Loader loading={loader} />
    </Container>
  );
};

export default TopCategories;
