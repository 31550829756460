import "./App.css";
import { Route, Routes } from "react-router-dom";
import LandingPage from "../src/Component/LandingPage";
import VendorList from "./Component/VendorList/VendorList";
import VendorProfile from "./Component/VendorProfile/VendorProfile";
import MyAccountPage from "./Component/MyAccount/MainAccountPage";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getUserProfile } from "./Component/Action/AuthAction";
import Cookies from "js-cookie";
import LandingPage1 from "./Component/LandingPage1";
import TermAndCondition from "./Component/TermAndCondition";
import PrivacyAndPolicy from "./Component/PrivacyAndPolicy";
import AboutUs from "./Component/AboutUsPage";

function App() {
  const dispatch = useDispatch();

  const [userArea, setUserArea] = useState(Cookies.get("userArea") == 'undefined' || Cookies.get("userArea") == undefined?'Noida':Cookies.get("userArea"));

  const userDataString = Cookies.get("data");
  let userData;
  if (userDataString) {
    try {
      userData = JSON.parse(userDataString);
    } catch (error) {
      console.error("Error parsing userData:", error);
    }
  }

  const userId = userData?._id;

  useEffect(() => {
    if (userId) {
      let data = {
        id: userId,
      };
      dispatch(getUserProfile(data));
    } else {
      // Handle the case when userId is not available,
    }
  }, []);

  useEffect(() => {
    Cookies.set("userArea", userArea, {
      secure: false,
      sameSite: "strict",
      expires: 365,
    });
  }, [userArea]);

  return (
    <div>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/sub-category" element={<LandingPage1 />} />
        <Route path="/vendor-list" element={<VendorList />} />
        <Route path="/vendor-profile" element={<VendorProfile />} />
        <Route path="/my-account" element={<MyAccountPage />} />
        <Route path="/term-condition" element={<TermAndCondition />} />
        <Route path="/privacy-policy" element={<PrivacyAndPolicy />} />
        <Route path="/about-us" element={<AboutUs />} />
      </Routes>
    </div>
  );
}

export default App;
