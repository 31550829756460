import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import img from "../../Assets/kitchen_cleaning.svg";
import { useDispatch } from "react-redux";
import { onFetchNotification } from "../Action/FetchAction";

const Notification = () => {

  const dispatch = useDispatch();

  const [notification , setNotification] = useState([])

  useEffect(()=>{
    dispatch(onFetchNotification(setNotification))
  },[])

  return (
    <>
      <div className="bg-box p-4 ">
        <h3 className="py-4">Notification</h3>
        <hr />
       {notification?.map((elem,id)=>{
        return (
          <>
           <div className="bg-box p-4 my-4">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <img
                src={img}
                style={{ width: "50px", height: "50px", borderRadius: "50%" }}
              />
              <h6 className="mx-4">{elem.title}</h6>
              <h5>{elem.description}</h5>
            </div>
            <h6>9:40pm</h6>
          </div>
        </div>
          </>
        )
       })}

        
      </div>
    </>
  );
};

export default Notification;
