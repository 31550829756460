import React, {useState} from 'react'
import { Form, Modal } from 'react-bootstrap'
import { onSendQuery } from '../Action/FetchAction';
import { RxCross2 } from "react-icons/rx";
import { useDispatch } from 'react-redux';

const BestDeal = ({modalShow , setModalShow , vendorId}) => {
    const dispatch = useDispatch();

    const handleFalse = () => {
        setModalShow(false);
      };

    const [deal , setDeal] = useState({
        name :"",
        email:"",
        mobile:"",
        query:""
      })

      const handleChange=(e)=>{
        const {name , value} = e.target
        setDeal({
          ...deal,
          [name]:value
        })
      }
      
      const handleQuery=()=>{
        let data={
        vendorId: vendorId,
          name: deal.name,
          email: deal.email,
          number: deal.mobile,
          query:deal.query
      }
      dispatch(onSendQuery(data ,handleFalse))
      }

  return (
    <div><Modal
    show={modalShow}
    onHide={() => setModalShow(false)}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    className="p-4"
  >
    <Modal.Body>
      <div closeButton style={{ borderBottom: "3px solid #D2021C" }}>
        <div
          style={{ position: "absolute", right: "3%", fontSize: "22px" }}
          onClick={handleFalse}
        >
          {" "}
          <RxCross2 />
        </div>
        <Modal.Title id="contained-modal-title-vcenter" className="p-4">
          <h6>Best Deals</h6>
          <h5>Add your best deal name</h5>
        </Modal.Title>
      </div>
      <Form className="p-4 ">
        <div className="row ">
          <div className="col-md-12 ">
            <Form.Group className="input-box" controlId="">
              <Form.Control
                type="text"
                placeholder="Enter Your  name"
                name='name'
                value={deal.name}
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          
        </div>
        <div className="row ">
          <div className="col-md-6 ">
            <Form.Group className="input-box" controlId="">
              <Form.Control
                type="number"
                placeholder="Enter Your mobile no"
                name = 'mobile'
                value={deal.mobile}
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="col-md-6">
            <Form.Group className="input-box" controlId="">
              <Form.Control 
              type="email" 
              name='email'
              placeholder="Enter your email Id"
              value={deal.email}
              onChange={handleChange}
              />
            </Form.Group>
          </div>
        </div>

        <div className="row ">
          <div className="col-md-12 ">
            <Form.Group
              className="input-box"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Control
                as="textarea"
                rows={8}
                placeholder="Enter your query"
                name='query'
                value={deal.query}
                onChange={handleChange}
              />
            </Form.Group>
          </div>
        </div>
      </Form>
    </Modal.Body>

    <div className="text-center mb-5">
      <button className="category-btn" onClick={handleQuery}>Submit</button>
    </div>
    {/* <Button onClick={handleFalse}>Close</Button> */}
  </Modal></div>
  )
}

export default BestDeal