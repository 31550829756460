import React,{useState} from 'react';
import { Container , Form } from 'react-bootstrap';
import logo from "../../../Assets/logo.png"
import { useNavigate } from 'react-router-dom';
import Modal from "react-bootstrap/Modal";
import { RxCross2 } from "react-icons/rx";

const Header1 = () => {
    const navigate = useNavigate()

    const [modalShow1, setModalShow1] = useState(false);
    const [modalShow2, setModalShow2] = useState(false);
    const [advertising , setAdvertising] = useState({
        name :"",
        mobile:"",
        email:"",
        business:"",
        exp:"",
        address:""
    })

    const [listing , setListing] = useState({
        name :"",
        mobile:"",
        email:"",
        business:"",
        exp:"",
        address:""
    })

    const handleFalse1 = () => {
        setModalShow1(false);
      };
      const handleTrue1 = () => {
        setModalShow1(true);
      };
    
      const handleFalse2 = () => {
        setModalShow2(false);
      };
      const handleTrue2 = () => {
        setModalShow2(true);
      };

      const handleChange = (type, e) => {
        const { name, value } = e.target;
        if (type === "advert") {
            setAdvertising({
                ...advertising,
                [name]: value,
              });
        } else {
            setListing({
              ...listing,
              [name]: value,
            });
        }
      };

  return (
    <Container fluid className='bg-box'>
        <div className='row header1 '>
            <div className='col-md-10'>
                <div className='display-justify-between'>
                <div onClick={()=>navigate("/")} className='cursor-pointer'><img src={logo} style={{width:"20rem"}} /></div>
                    <div className='head-btn-div m-0'>
                        <div><button onClick={handleTrue1} className='head-btn'>Advertising</button></div>
                        <div><button onClick={handleTrue2} className='head-btn'>free Listing</button></div>
                    </div>
                    
                </div>
            </div>
        </div>

        <Modal
        show={modalShow1}
        onHide={() => setModalShow1(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="p-0"
      >
        <Modal.Body>
          <div closeButton style={{ borderBottom: "3px solid #D2021C" }}>
            <div
              style={{ position: "absolute", right: "3%", fontSize: "22px" }}
              onClick={handleFalse1}
            >
              {" "}
              <RxCross2 />
            </div>
            <Modal.Title id="contained-modal-title-vcenter" className="p-4">
              <div className="text-center">
                <h6>Register your business for free India's leading search engine </h6>
              </div>
            </Modal.Title>
          </div>
          <Form className="p-4 ">
            <h6 className="text-center">Fill your details</h6>
            <div className="row ">
              <div className="col-md-6 ">
                <Form.Group className="input-box" controlId="">
                  <Form.Control
                    type="text"
                    placeholder="Enter Your name"
                    value={advertising.name}
                    name="fName"
                    onChange={(e) => handleChange("advert", e)}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6 align-items-center d-flex ">
                <Form.Select
                  aria-label="Default select example"
                  name="gender"
                  onChange={(e) => handleChange("advert", e)}
                >
                  <option>Choose your gender</option>
                  <option value="1">Male</option>
                  <option value="2">Female</option>
                </Form.Select>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-6 ">
                <Form.Group className="input-box" controlId="">
                  <Form.Control
                    type="text"
                    value={advertising.mobile}
                    placeholder="Enter Your mobile no"
                    onChange={(e) => handleChange("advert", e)}
                    name="mobileNo"
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="input-box" controlId="">
                  <Form.Control
                    type="text"
                    placeholder="Enter your email Id"
                    value={advertising.email}
                    onChange={(e) => handleChange("advert", e)}
                    name="email"
                  />
                </Form.Group>
              </div>
            </div>

            <div className="row ">
              <div className="col-md-6 ">
              <Form.Group className="input-box" controlId="">
                  <Form.Control
                    type="text"
                    placeholder="year of experiences"
                    value={advertising.exp}
                    onChange={(e) => handleChange("advert", e)}
                    name="exp"
                  />
                </Form.Group>
              </div>
              <div className="col-md-6 ">
              <Form.Group className="input-box" controlId="">
                  <Form.Control
                    type="text"
                    placeholder="Enter your business name"
                    value={advertising.business}
                    onChange={(e) => handleChange("advert", e)}
                    name="business"
                  />
                </Form.Group>
              </div>
            </div>
          </Form>
        </Modal.Body>

        <div className="text-center mb-5">
          <button className="category-btn" >
            Submit
          </button>
        </div>
        {/* <Button onClick={handleFalse}>Close</Button> */}
      </Modal>

      <Modal
        show={modalShow2}
        onHide={() => setModalShow2(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="p-4"
      >
        <Modal.Body>
          <div closeButton style={{ borderBottom: "3px solid #D2021C" }}>
            <div
              style={{ position: "absolute", right: "3%", fontSize: "22px" }}
              onClick={handleFalse2}
            >
              {" "}
              <RxCross2 />
            </div>
            <Modal.Title id="contained-modal-title-vcenter" className="p-4">
              <div className="text-center">
                <h6>Register your business for free India's leading search engine </h6>
              </div>
            </Modal.Title>
          </div>
          <Form className="p-4 ">
            <h6 className="text-center">Fill your details</h6>
            <div className="row ">
              <div className="col-md-6 ">
                <Form.Group className="input-box" controlId="">
                  <Form.Control
                    type="text"
                    placeholder="Enter Your name"
                    value={advertising.name}
                    name="fName"
                    onChange={(e) => handleChange("advert", e)}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6 align-items-center d-flex ">
                <Form.Select
                  aria-label="Default select example"
                  name="gender"
                  onChange={(e) => handleChange("advert", e)}
                >
                  <option>Choose your gender</option>
                  <option value="1">Male</option>
                  <option value="2">Female</option>
                </Form.Select>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-6 ">
                <Form.Group className="input-box" controlId="">
                  <Form.Control
                    type="text"
                    value={advertising.mobile}
                    placeholder="Enter Your mobile no"
                    onChange={(e) => handleChange("advert", e)}
                    name="mobileNo"
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="input-box" controlId="">
                  <Form.Control
                    type="text"
                    placeholder="Enter your email Id"
                    value={advertising.email}
                    onChange={(e) => handleChange("advert", e)}
                    name="email"
                  />
                </Form.Group>
              </div>
            </div>

            <div className="row ">
              <div className="col-md-6 ">
              <Form.Group className="input-box" controlId="">
                  <Form.Control
                    type="text"
                    placeholder="year of experiences"
                    value={advertising.exp}
                    onChange={(e) => handleChange("advert", e)}
                    name="exp"
                  />
                </Form.Group>
              </div>
              <div className="col-md-6 ">
              <Form.Group className="input-box" controlId="">
                  <Form.Control
                    type="text"
                    placeholder="Enter your business name"
                    value={advertising.business}
                    onChange={(e) => handleChange("advert", e)}
                    name="business"
                  />
                </Form.Group>
              </div>
            </div>
          </Form>
        </Modal.Body>

        <div className="text-center mb-5">
          <button className="category-btn" >
            Submit
          </button>
        </div>
        {/* <Button onClick={handleFalse}>Close</Button> */}
      </Modal>
    </Container>
  )
}

export default Header1