import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { getBaseUrl } from "../../Global";

export const onLoginUser =
  (data, setLoader, handleTrue2, handleFalse) => async (dispatch) => {
    let config = {
      method: "post",
      url: getBaseUrl() + "/user/sendotp",
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      data: data,
    };

    try {
      let res = await axios(config);
      if (res.data) {
        
        toast.success("OTP sent");
        handleTrue2();
        handleFalse()
      } else {
        setLoader(false);
        toast.warning(res.data.message);
      }
      
    } catch (error) {
      console.log(error);
      setLoader(false);
      toast.warning("Login failed");
      return error.response;
    }
  };

export const onVerifyOtp =(data, setLoader, handleTrue1 , handleFalse2) => async (dispatch) => {
    let config = {
      method: "post",
      url: getBaseUrl() + "/user/verifyotp",
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      data: data,
    };

    try {
      let res = await axios(config);
      if (res.data.data==="null") {
        toast.info("Fill your details");
        handleTrue1();
      } else {
        let data = res.data.data;
        const token = res.data.data.token;
        Cookies.set("token", token, { secure: false }, { sameSite: "strict" }, { expires: 365 });
        Cookies.set("auth", true, { secure: false }, { sameSite: "strict" }, { expires: 365 });
        Cookies.set("data", JSON.stringify(res.data.data.newUser), { secure: false }, { sameSite: "strict" }, { expires: 365 });
        toast.success("Login Successfully");

        let userId ={
          id:res?.data?.data?.newUser?._id
        }
        dispatch(getUserProfile(userId));

        // dispatch({ type: "USER_LOGIN", payload: data });
      }
      handleFalse2()
    } catch (error) {
      console.log(error);
      setLoader(false);
      toast.warning("Login failed");
      return error.response;
    }
  };

  export const onRegisterUser =
  (data, setLoader,handleFalse1) => async (dispatch) => {
    let config = {
      method: "post",
      url: getBaseUrl() + "/user/registeruser",
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      data: data,
    };

    try {
      let res = await axios(config);
      if (res.data) {
        let data = res.data.data;
        const token = (res.data.data.token);
        Cookies.set("token", token, { secure: false }, { sameSite: "strict" }, { expires: 365 });
        Cookies.set("auth", true, { secure: false }, { sameSite: "strict" }, { expires: 365 });
        Cookies.set("data", JSON.stringify(res.data.data.newUser), { secure: false }, { sameSite: "strict" }, { expires: 365 });
        toast.success("Register user successfully");

        let userId ={
          id:res?.data?.data?.user?._id
        }
        dispatch(getUserProfile(userId));

        // dispatch({ type: "USER_LOGIN", payload: data });
    
      } else {
        toast.success("Login Successfully");
      }
      handleFalse1()
    } catch (error) {
      console.log(error);
      setLoader(false);
      toast.warning("Login failed");
      return error.response;
    }
  };

  export const onUpdateUser =
  (data, setLoader,handleFalse1 , handleLogout ) => async (dispatch) => {
    let config = {
      method: "post",
      url: getBaseUrl() + "/user/updateuser",
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      data: data,
    };

    try {
      let res = await axios(config);
      if (res.data) {
        toast.success("User details Update successfully");
        handleFalse1()
        handleLogout()
      } 
      
    } catch (error) {
      console.log(error);
      setLoader(false);
      toast.warning();
      return error.response;
    }
  };

  export const getUserProfile =(data) => async (dispatch) => {
    let config = {
      method: "post",
      url: getBaseUrl() + "/user/getuserbyid",
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      data: data,
    };

    try {
      let res = await axios(config);
      if (res.data) {
        let data = res.data;
        dispatch({ type: "USER_LOGIN", payload: data });
      } else {
        toast.warning(res.data.message);
      }
      
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };

  export const onLogoutUser = (handleClose,navigate)=>async(dispatch)=>{
    Cookies.remove("token", { secure: true }, { sameSite: "strict" }, { expires: 365 });
    Cookies.remove("auth", { secure: true }, { sameSite: "strict" }, { expires: 365 });
    Cookies.remove("data",  { secure: true }, { sameSite: "strict" }, { expires: 365 });
    toast.success("Logout successfully");
    if(handleClose){
      handleClose()
    }
     navigate("/")

    dispatch({ type: "USER_LOGIN", payload: "" });
  }

  export const onSignInStatus=(type)=>async(dispatch)=>{
    dispatch({ type: "IS_LOGIN", payload: type });
  }


