import React from "react";
import Logo from "../../Assets/logo.png";
import twitter from "../../Assets/Icons/twitter.png";
import linkedin from "../../Assets/Icons/linkedin.png";
import facebook from "../../Assets/Icons/facebook.png";
import instagram from "../../Assets/Icons/instagram.png";
import { Link, useNavigate } from "react-router-dom";
// import twitter from "../../Assets/Icons/twitter.png";
// import linkedin from "../../Assets/Icons/linkedin.png";
// import facebook from "../../Assets/Icons/facebook.png";
// import instagram from "../../Assets/Icons/instagram.png";
const Footer = () => {
  const navigate = useNavigate();
  return (
    <div>
      <section class="py-5" style={{ backgroundColor: "#f5f5f5" }}>
        <div class="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="row">
                <div class="row justify-content-between">
                  <div class="col-sm-6 col-md-4 col-lg-3">
                    <div class="address">
                      <h6 class="mb-2 text-dark">
                        <a class="navbar-brand text-dark">
                          <img
                            src={Logo}
                            alt=""
                            class="img-fluid"
                            style={{ width: "70%" }}
                          />
                        </a>
                      </h6>
                     <p className="m-0"> Home Shifting Mart is a dedicated and renowned online
                      reference platform to find out of list of packers and
                      movers, Transport service, Hire truck tempo service, ware
                      house, Car and bike Transportation service in India.</p>
                      <ul class="list-unstyled mb-0 d-flex align-items-center justify-content-start">
                  <li class="d-flex align-items-center ">
                    <Link to="">
                      <img
                        style={{ width: "40px" , height:"40px" }}
                        src={twitter}
                        alt="twitter-icon"
                      ></img>
                    </Link>
                  </li>
                  <li class="d-flex align-items-center ">
                    <Link to="">
                      <img
                        style={{ width: "40px" , height:"40px" }}
                        src={linkedin}
                        alt="linkedin-icon"
                      ></img>
                    </Link>
                  </li>
                  <li class="d-flex align-items-center ">
                    <Link to="">
                      <img
                        style={{ width: "40px" , height:"40px" }}
                        src={facebook}
                        alt="facebook-icon"
                      ></img>
                    </Link>
                  </li>
                  <li class="d-flex align-items-center ">
                    <Link to="">
                      <img
                        style={{ width: "40px" , height:"40px" }}
                        src={instagram}
                        alt="instagram-icon"
                      ></img>
                    </Link>
                  </li>
                </ul>
                    </div>
                  </div>

                  <div class="col-6 col-md-4 col-lg-3">
                    <div class="address">
                      <h6 class="mb-2 text-dark">Most Popular Categories</h6>
                      <ul class="list-unstyled">
                        <li
                          class=" mb-1"
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate("/about-us")}
                        >
                          <a className="footer-text ">About Us</a>
                        </li>
                        <li class="  mb-1">
                          <a href=" " className="footer-text ">
                            Complaints
                          </a>
                        </li>
                        <li class="  mb-1">
                          <a href=" " className="footer-text ">
                            Career
                          </a>
                        </li>
                        <li
                          class="  mb-1"
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate("/privacy-policy")}
                        >
                          <a className="footer-text ">Privacy Policy</a>
                        </li>
                        <li
                          class="mb-1"
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate("/terms-of-service")}
                        >
                          <a className="footer-text ">Terms Of Services</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-6 col-md-4 col-lg-2">
                    <div class="address">
                      <h6 class="mb-2 text-dark"> More from HSM</h6>
                      <ul class="list-unstyled">
                        <li class="mb-1">
                          <a href="" className="footer-text ">
                            Appliance Repair
                          </a>
                        </li>
                        <li class="mb-1">
                          <a href="contact-us.html" className="footer-text ">
                            Home Painting
                          </a>
                        </li>
                        <li class="mb-1">
                          <a href="" className="footer-text ">
                            Cleaning & Pest
                          </a>
                        </li>
                        <li class="mb-1">
                          <a href="" className="footer-text ">
                            Disinfection
                          </a>
                        </li>
                        <li class="mb-1">
                          <a href="" className="footer-text ">
                            Home Repairs
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-6 col-md-4 col-lg-2">
                    <div class="address">
                      <h6 class="mb-2 text-dark">Customer Services</h6>
                      <ul class="list-unstyled">
                        <li class="  mb-1">
                          <a className="footer-text ">Churchgate,Mumbai</a>
                        </li>
                        <li class="mb-1">
                          <a href="contact-us.html" className="footer-text ">
                            Home
                          </a>
                        </li>
                        <li class="mb-1">
                          <a href="" className="footer-text ">
                            Janakpuri,Delhi
                          </a>
                        </li>
                        <li class="mb-1">
                          <a href="" className="footer-text ">
                            Kurla East,Mumbai
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                 
                  <hr class="border border-white ms-auto w-75 d-none d-lg-block" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer class="py-4" style={{ backgroundColor: "#f5f5f5" }}>
        <div class="container">
          <div class="row ">
            <div class=" col-12 col">
              <div class="d-flex justify-content-center align-items-center flex-column flex-lg-row">
                
                <p class=" text-dark">
                  © Copyright 2022 By Home Shifting Mart
                </p>
                <Link to={"/privacy-policy"} className="footer-text px-4 ">
                  Privacy Policy
                </Link>
                <Link to={"/term-condition"} className="footer-text px-4 ">
                  Term and Conditions for Users
                </Link>

                <a className="footer-text px-4 ">Term and Conditions for Vendors</a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
