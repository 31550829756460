import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BestDeal from "./BestDeal";
import { IoStar } from "react-icons/io5";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { FaPhoneAlt } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { onSignInStatus } from "../Action/AuthAction";
import Login from "../Dialogbox/Login";
import DataNotFound from "../Loader/DataNotFound";

const VendorAllTab = ({ category, location }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userAuth = useSelector(state=>state.auth)

  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [mobile, setMobile] = useState(false);

  const handleTrue = (e) => {
    e.stopPropagation();
    if(userAuth?.user?.name){
      setModalShow(true);
    }else{
      toast.warning("Please login first")
      navigate("/")
    }
    
  };
  const handleMobile = (e) => {
    e.stopPropagation();
    if(userAuth?.user?.name){
      setMobile(!mobile);
    }else{
      toast.warning("Please login first")
      // navigate("/")
      setModalShow1(true)
    }
  };

  const handleFalse = (e) => {
    e.stopPropagation();
    setMobile(false);
  };

  const handleNavigate = (id) => {
    navigate("/vendor-profile", {
      state: {
        location: location?.state?.location,
        services: location?.state?.services,
        vendorId: id,
      },
    });
  };

  return (
    <>
      <div className="d-flex justify-content-around">
        <div className="bg-box nav-filter-btn">
          <h5 className="">All</h5>
        </div>
        <div className="bg-box nav-filter-btn">
          <h5>
            <IoStar fontSize={"26px"} style={{ marginRight: "10px" }} />
            Top Rated
          </h5>
        </div>
        <div className="bg-box nav-filter-btn">
          <h5>
            {" "}
            <RiVerifiedBadgeFill
              fontSize={"26px"}
              style={{ marginRight: "10px", color: "green" }}
            />{" "}
            Verified
          </h5>
        </div>
        <div className="bg-box">
          <div className="expert-bg nav-filter-btn">
            <h5>Expert</h5>
          </div>
        </div>
      </div>

      {category.length>0?
    category.map((category,id)=>{
      return(
        <><div
        className="row woker-list"
        onClick={() => handleNavigate(category?._id)}
      >
        <div className="col-md-4 col-4 p-0 ">
          <img src={category?.profileImage} style={{height:"22rem" ,padding:"2rem", width:"100%"}} />
        </div>
        <div className="col-md-8 col-8 px-5 d-flex justify-cntent-start align-items-center">
          <div className="py-2">
            <h6 className="text-start">{category?.companyName}</h6>
            <div className="d-flex justify-content-start">
              <div>
                <p className="worker-review">
                  <IoStar fontSize={"2.6rem"} style={{ marginRight: "1.0rem" }} />
                  {category?.rating}
                </p>
              </div>
              <div>
                <p className="">{category?.ratingCount} rating</p>
              </div>
              <div className="expert-bg">
                <p className="worker-expert">Expert</p>
              </div>
            </div>
            <p className="m-0 text-start">
              {category?.address?.map((addressItem, index) => (
                <React.Fragment key={index}>
                  {addressItem.area} ,{addressItem.buldingName} ,
                  {addressItem.city} ,{addressItem.landmark} ,
                  {addressItem.pinCode} ,
                </React.Fragment>
              ))}
            </p>
            <div className="d-flex position-relative">
              <button className="worker-list-call" onClick={handleMobile}>
                <FaPhoneAlt fontSize={"2.6rem"} style={{ marginRight: "1.0rem" }} />
                Call Now
              </button>
              {mobile ? (
                <div className="mobile-modal bg-box">
                  <div className="d-flex justify-content-between">
                    <p className="mobile-modal-text">Contact information</p>
                  <div    style={{ position: "absolute", right: "3%", fontSize: "2.2rem" , top:'10%' }} onClick={handleFalse}>
                    <RxCross2 />
                  </div>
                  </div>
                  <div className="text-start"><FaPhoneAlt
                    fontSize={"2.0rem"}
                    style={{ marginRight: "1.0rem" }}
                  />
                  {category?.mobileNumber}</div>
                </div>
              ) : (
                ""
              )}
              <button onClick={handleTrue} className="worker-list-deal">
                Best Deal
              </button>
            </div>
          </div>
        </div>
      </div></>
      )
    })  
   :<DataNotFound/> }

      <BestDeal
        modalShow={modalShow}
        setModalShow={setModalShow}
        vendorId={category?._id}
      />
      <Login 
      modalShow={modalShow1}
      modalShow2={modalShow2}
      setModalShow={setModalShow1}
      setModalShow2={setModalShow2}
      />
    </>
  );
};

export default VendorAllTab;
