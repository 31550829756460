import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import downloadapp from "../../Assets/download_app.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCategories, fetchAllSubCategories, onSearchServices } from "../Action/FetchAction";
import Cookies from "js-cookie";
import Loader from "../Loader/Loader";

const SubCategories = ({location }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userArea = Cookies.get("userArea")
  const [category, setCategory] = useState([]);
  const [loader , setLoader] = useState(false)

  const locationServices = useSelector((state) => state.fetch);

  useEffect(() => {
    setLoader(true)
    let data = {
        serviceId:location?.state?.categoryId
    }
    dispatch(fetchAllSubCategories(data , setCategory , setLoader));
  }, []);

  const handleCategory = (elem) => {
    navigate('/vendor-list' , {state:{ categoryId:elem?._id ,  location: userArea, services: elem.name,}})
  };

  return (
    <Container fluid className="mt-5">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <div className="text-center">
            <h1>{location?.state?.name} Subcategories</h1>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Aspernatur iusto nemo fugiat beatae deleniti natus praesentium
              placeat delectus? Voluptate natus dolor quisquam odit.{" "}
            </p>
          </div>
          <div className="row justify-content-center p-4">
            {category?.map((elem, id) => {
              return (
                <>
                  <div
                    className="col-md-3 col-sm-4 col-3 category-img-div"
                    onClick={() => handleCategory(elem)}
                  >
                    <img src={elem.image} />
                    <h5>{elem.name}</h5>
                  </div>
                </>
              );
            })}
          </div>
          {/* <div className="text-center p-5">
            <button className="category-btn">ALL SERVICES</button>
          </div> */}
        </div>
        <img src={downloadapp} />
      </div>
      <Loader loading={loader}/>
    </Container>
  );
};

export default SubCategories;
