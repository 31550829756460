import React, { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { AiOutlineBell } from "react-icons/ai";
import Modal from "react-bootstrap/Modal";
import { RxCross2 } from "react-icons/rx";
import logo from "../../../Assets/logo.png";
import { useDispatch, useSelector } from "react-redux";
import {
  onLoginUser,
  onRegisterUser,
  onVerifyOtp,
} from "../../Action/AuthAction";
import OtpInput from "react-otp-input";
import Offcanvas from "./Offcanvas";
import Login from "../../Dialogbox/Login";

const Header = () => {

  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [offcanvas , setOffcanvas] = useState(false)

  const [code, setCode] = useState("");
  const [loginNo, setLoginNo] = useState("");
  const [signup, setSignup] = useState({
    fName: "",
    // lName: "",
    email: "",
    mobileNo: "",
    gender: "",
    img: "",
  });
  const [loader, setLoader] = useState(false);

  const userData = useSelector((state) => state.auth);

  const handleFalse = () => {
    setModalShow(false);
  };
  const handleTrue = () => {
    setModalShow(true);
  };

  const handleFalse1 = () => {
    setModalShow1(false);
  };
  const handleTrue1 = () => {
    setModalShow1(true);
  };

  const handleFalse2 = () => {
    setModalShow2(false);
  };
  const handleTrue2 = () => {
    setModalShow2(true);
  };

  const handleChange = (type, e) => {
    const { name, value } = e.target;
    if (type === "login") {
      setLoginNo(value);
    } else {
      if (name === "img") {
        const file = e.target.files[0];
        setSignup({
          ...signup,
          img: file,
        });
      } else {
        setSignup({
          ...signup,
          [name]: value,
        });
      }
    }
  };

  const handleLogin = () => {
    const data = {
      number: loginNo,
    };
    dispatch(onLoginUser(data, setLoader, handleTrue2, handleFalse));
  };

  const handleContinue = () => {
    const data = {
      number: loginNo,
      otp: code,
    };
    dispatch(onVerifyOtp(data, setLoader, handleTrue1, handleFalse2));
  };

  const handleRegister = () => {
    const formdata = new FormData();
    formdata.append("email", signup.email);
    formdata.append("name", signup.fName);
    formdata.append("number", signup.mobileNo);
    formdata.append("Image", signup.img);
    formdata.append("gender", signup.gender === "1" ? "male" : "female");

    dispatch(onRegisterUser(formdata, setLoader, handleFalse1));
  };

  const handleChangeOtp = (code) => setCode(code);

  const handleOffcanvas=()=>{
    setOffcanvas(true)
  }

  useEffect(()=>{
    dispatch({ type: "IS_LOGIN", payload: modalShow });
  },[])

  return (
    <Container fluid style={{ backgroundColor: "#EFEFEF" }}>
      <div className="row justify-content-center align-items-center">
        <div className="col-md-10">
          <div className="d-flex justify-content-between">
            <div className="d-flex m-2">
              <div>
                <p style={{ marginRight: "20px" }}>+917840044700</p>
              </div>
              <div>
                <p>homeshiftingmart@gmail.com</p>
              </div>
            </div>
            <div className="d-flex m-2">
              <div>
                <AiOutlineBell
                  style={{
                    marginRight: "20px",
                    fontSize: "24px",
                    marginTop: "10px",
                  }}
                />
              </div>

              <div>
                
                  {userData?.user ? <p>{userData?.user?.name}</p> : 
                  <p
                  style={{ marginRight: "20px", cursor: "pointer" }}
                  onClick={handleTrue}> Login</p>
                }
                
              </div>
              <div>
                {userData?.user ? (
                  <img onClick={handleOffcanvas} src={userData?.user?.profile_image}
                   height={"40px"} width={"40px"} style={{borderRadius:"50%" , marginTop:"5px"}} alt="User Profile" />
                ) : (
                  <p style={{ cursor: "pointer" }} onClick={handleTrue1}>
                    Sign Up
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Login modal --------------------------------------Popup */}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className=""
      >
        <Modal.Body>
          <div closeButton style={{ borderBottom: "3px solid #D2021C" }}>
            <div
              style={{ position: "absolute", right: "3%", fontSize: "22px" }}
              onClick={handleFalse}
            >
              {" "}
              <RxCross2 />
            </div>
            <Modal.Title id="contained-modal-title-vcenter" className="p-4">
              <div className="text-center">
                <img src={logo} />
              </div>
            </Modal.Title>
          </div>
          <Form className="p-4 ">
            <h6 className="text-center">Welcome for seamless experience</h6>
            <div className="row justify-content-center">
              <div className="col-md-8 ">
                <p className="text-center">Enter your Mobile No.</p>
                <Form.Group className="input-box" controlId="">
                  <Form.Control
                    type="text"
                    placeholder="Enter Your mobile no"
                    value={loginNo}
                    onChange={(e) => handleChange("login", e)}
                    name="loginNo"
                  />
                </Form.Group>
              </div>
            </div>
          </Form>
        </Modal.Body>

        <div className="text-center mb-5">
          <button className="category-btn" onClick={handleLogin}>
            Login with OTP
          </button>
        </div>
      </Modal>
   

      {/* OTP------------------------ modal --------------------------------------Popup */}

      <Modal
        show={modalShow2}
        onHide={() => setModalShow2(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className=""
      >
        <Modal.Body>
          <div closeButton style={{ borderBottom: "3px solid #D2021C" }}>
            <div
              style={{ position: "absolute", right: "3%", fontSize: "22px" }}
              onClick={handleFalse2}
            >
              {" "}
              <RxCross2 />
            </div>
            <Modal.Title id="contained-modal-title-vcenter" className="p-4">
              <div className="text-center">
                <img src={logo} />
              </div>
            </Modal.Title>
          </div>
          <Form className="p-4 ">
            <h6 className="text-center">Welcome for seamless experience</h6>
            <div className="row justify-content-center">
              <div className="col-md-8 ">
                <p className="text-center">Enter the code sent to </p>
                <OtpInput
                  value={code}
                  onChange={handleChangeOtp}
                  numInputs={4}
                  separator={<span>-</span>}
                  renderInput={(props) => <input {...props} />}
                  isInputNum={true}
                  shouldAutoFocus={true}
                  inputStyle={{
                    border: "1px solid #000",
                    borderRadius: "8px",
                    width: "54px",
                    height: "54px",
                    fontSize: "16px",
                    color: "#000",
                    fontWeight: "400",
                    caretColor: "blue",
                    margin: "auto",
                  }}
                  focusStyle={{
                    border: "1px solid #CFD3DB",
                    outline: "none",
                  }}
                />
              </div>
            </div>
          </Form>
        </Modal.Body>

        <div className="text-center mb-5">
          <button className="category-btn" onClick={handleContinue}>
            Continue
          </button>
        </div>
      </Modal>

      {/* Register------------------------------------ modal --------------------------------------Popup */}

      <Modal
        show={modalShow1}
        onHide={() => setModalShow1(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className=""
      >
        <Modal.Body>
          <div closeButton style={{ borderBottom: "3px solid #D2021C" }}>
            <div
              style={{ position: "absolute", right: "3%", fontSize: "22px" }}
              onClick={handleFalse1}
            >
              {" "}
              <RxCross2 />
            </div>
            <Modal.Title id="contained-modal-title-vcenter" className="p-4">
              <div className="text-center">
                <img src={logo} />
              </div>
            </Modal.Title>
          </div>
          <Form className="p-4 ">
            <h6 className="text-center">Fill your details</h6>
            <div className="row ">
              <div className="col-md-6 ">
                <Form.Group className="input-box" controlId="">
                  <Form.Control
                    type="text"
                    placeholder="Enter Your name"
                    value={signup.fName}
                    name="fName"
                    onChange={(e) => handleChange("signup", e)}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6 align-items-center d-flex ">
                <Form.Select
                  aria-label="Default select example"
                  name="gender"
                  onChange={(e) => handleChange("signup", e)}
                >
                  <option>Choose your gender</option>
                  <option value="1">Male</option>
                  <option value="2">Female</option>
                </Form.Select>
              </div>
              {/* <div className="col-md-6">
                <Form.Group className="input-box" controlId="">
                  <Form.Control
                    type="text"
                    placeholder="Enter your last name"
                    value={signup.lName}
                    onChange={(e) => handleChange("signup", e)}
                    name="lName"
                  />
                </Form.Group>
              </div> */}
            </div>
            <div className="row ">
              <div className="col-md-6 ">
                <Form.Group className="input-box" controlId="">
                  <Form.Control
                    type="text"
                    value={signup.mobileNo}
                    placeholder="Enter Your mobile no"
                    onChange={(e) => handleChange("signup", e)}
                    name="mobileNo"
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="input-box" controlId="">
                  <Form.Control
                    type="text"
                    placeholder="Enter your email Id"
                    value={signup.email}
                    onChange={(e) => handleChange("signup", e)}
                    name="email"
                  />
                </Form.Group>
              </div>
            </div>

            <div className="row ">
              <div className="col-md-6 ">
                <Form.Group className="input-box" controlId="">
                  <Form.Control
                    type="file"
                    accept="png/jpg/jpeg"
                    onChange={(e) => handleChange("signup", e)}
                    name="img"
                  />
                </Form.Group>
              </div>
            </div>
          </Form>
        </Modal.Body>

        <div className="text-center mb-5">
          <button className="category-btn" onClick={handleRegister}>
            Submit
          </button>
        </div>
        {/* <Button onClick={handleFalse}>Close</Button> */}
      </Modal>

      <Offcanvas offCanvas={offcanvas}  setOffcanvas={setOffcanvas} />
    </Container>
  );
};

export default Header;
