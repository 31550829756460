import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import banner from "../../Assets/banner.svg";
import { CiSearch } from "react-icons/ci";
import { useDispatch } from "react-redux";
import {
  onSearchLocation,
  onSearchServices,
  onSendLocationService,
} from "../Action/FetchAction";
import { useNavigate } from "react-router-dom";
import { FaLocationDot } from "react-icons/fa6";
import Cookies from "js-cookie";
import { RiMapPinFill } from "react-icons/ri";

const BannerPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userArea =
    Cookies.get("userArea") == "undefined" ||
    Cookies.get("userArea") == undefined
      ? "Noida"
      : Cookies.get("userArea");

  const [currentLocation, setCurrentLocation] = useState(null);

  const [location, setLocation] = useState(userArea);
  const [locationList, setLocationList] = useState([]);
  const [selectLocation, setSelectLocation] = useState("");
  const [locationDropdown, setLocationDropdown] = useState(false);

  const [services, setServices] = useState("");
  const [servicesListCategory, setServicesListCategory] = useState([]);
  const [servicesListVendor, setServicesListVendor] = useState([]);
  const [serviceDropdown, setServiceDropdown] = useState(false);

  const [cookieSet, setCookieSet] = useState(false);

  useEffect(() => {
    handleSearchLocation();
  }, [location]);

  const handleSearchLocation = () => {
    let data = {
      search: location,
    };
    dispatch(onSearchLocation(data, setLocationList));
  };

  useEffect(() => {
    handleSearchServices();
  }, [services]);

  const handleSearchServices = () => {
    let data = {
      search: services,
      location: location,
    };
    dispatch(
      onSearchServices(data, setServicesListCategory, setServicesListVendor)
    );
  };

  const handleDetectLocation = (e) => {
    e.stopPropagation();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          // setCurrentLocation({ latitude, longitude });
          const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
          fetch(url)
            .then((res) => res.json())
            .then((data) => setCurrentLocation(data));
          setCookieSet(true);
        },
        (error) => {
          console.error("Error getting location:", error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    if (cookieSet) {
      setLocation(currentLocation?.display_name);
      Cookies.set(
        "userArea",
        currentLocation?.display_name
          ? currentLocation?.display_name
          : userArea,
        { secure: false },
        { sameSite: "strict" },
        { expires: 365 }
      );
    }
  }, [handleDetectLocation]);

  useEffect(() => {
    dispatch(onSendLocationService(selectLocation, services));
  }, [location, services]);

  return (
    <div className="position-relative">
      <div className="banner-img">
        <img src={banner} />
      </div>
      <div className="banner-text">
        <h1>
          Your Logistics Service Solution is just one step away save upto 20% to
          30%
        </h1>
        <p className='main-banner-text'>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet natus
          voluptate dolor reprehenderit. In, quae eaque tempore, omnis similique
          voluptatum neque sequi voluptates enim temporibus, illum porro
          officiis consequatur ab!
        </p>
        <div className="banner-input">
          <div>
            <input
              type="text"
              value={location}
              placeholder="search location"
              onBlur={() => {
                setTimeout(() => {
                  setLocation(Cookies.get("userArea"));
                  setLocationDropdown(false);
                }, 500);
              }}
              onFocus={() => {
                setLocation("");
                setCurrentLocation("");
                setLocationDropdown(true);
              }}
              onChange={(e) => setLocation(e.target.value)}
            />
            {/* <RiMapPinFill className='map_icon' /> */}
            {locationDropdown ? (
              <div className="list-box box-scroll">
                <p
                  onClick={(e) => handleDetectLocation(e)}
                  className="p-4 text-primary"
                >
                  <FaLocationDot fontSize={"22px"} /> Detect location
                </p>
                {locationList?.map((elem, id) => (
                  <ul key={id}>
                    <li
                      onClick={() => {
                        setSelectLocation(elem.name);
                        setLocation(elem.name);
                        Cookies.set("userArea", elem.name, {
                          secure: false,
                          sameSite: "strict",
                          expires: 365,
                        });
                        // setLocationDropdown(false)
                      }}
                      className="list-div"
                    >
                      {elem.name}, {elem.stateName}
                    </li>
                  </ul>
                ))}
              </div>
            ) : null}
          </div>

          <div className="search_box">
            <div style={{position:"relative"}}>
              <input
                type="text"
                placeholder="search services/category"
                value={services}
                onBlur={() => {
                  setTimeout(() => {
                    setServiceDropdown(false);
                  }, 500);
                }}
                onFocus={() => setServiceDropdown(true)}
                onChange={(e) => setServices(e.target.value)}
              />
              <CiSearch className="search_icon" />
            </div>

            {serviceDropdown ? (
              <div className="list-box box-scroll">
              <div className="">
                {servicesListCategory?.map((elem, id) => (
                  <li
                    key={id}
                    onClick={() => {
                      setServicesListCategory([]);
                      setServices(elem.name, elem.stateName);
                      navigate("/vendor-list", {
                        state: {
                          location: userArea,
                          services: elem.name,
                          categoryId: elem._id,
                        },
                      });
                    }}
                    className="list-div"
                  >
                    {elem.name}
                  </li>
                ))}
              </div>
      
              <div className="">
                {servicesListVendor?.map((elem, id) => (
                  <li
                    key={id}
                    onClick={() => {
                      setServicesListVendor([]);
                      setServices(elem.name, elem.stateName);
                      navigate("/vendor-profile", {
                        state: {
                          location: userArea,
                          services: elem.name,
                          vendorId: elem._id,
                        },
                      });
                    }}
                    className="list-div"
                  >
                    {elem.companyName}
                  </li>
                ))}
              </div>
              </div>
            ):""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerPage;
